import React from 'react';

import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import style from "./style.module.scss";

class Page extends React.Component<any, any> {
    render() {
        return (<>
            <div className={style.NotEntity}/>
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);