import React from 'react';
import Layout from "../../layout";
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import {Service} from "../../api";
import {UserContext} from "../../context/UserContext";
import {Link} from "react-router-dom";
import {Utils} from "../../api/utils";
import style from "./style.module.scss";
import root_style from "../styles/layout.module.scss";
import CurrentDate from "../../api/utils/Date"

class Page extends React.Component<any, any> {

    state = {
        classe: [],
        loaded: true,
        current_month: null
    }

    static contextType = UserContext

    componentDidMount() {
        this.setState({loaded: false});
    }

    setHeader = () => {

        return (<>
            <div className={root_style.PageHeader}>
                <Link to='/'>
                    <i className='fa-solid fa-arrow-left'/>
                </Link>
                <h1>Mesagens</h1>
            </div>
        </>)
    }

    render() {
        const {loaded}:any = this.state;
        return (<>
            <Layout {...this.props} loaded={loaded} header={this.setHeader()}>
                <>
                </>
            </Layout>
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);