import React from 'react';
import Layout from "../../layout";
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import {Service} from "../../api";
import style from "./style.module.scss";
import {Utils} from "../../api/utils";
import {UserContext} from "../../context/UserContext";
import root_style from "../styles/layout.module.scss";
import {Link} from "react-router-dom";

class Page extends React.Component<any, any> {
    static contextType = UserContext;
    state = {
        loaded: true,
        data: {}
    }

    componentDidMount() {
        Promise.all([this.get_data()]).then((response: any) => {
            const [data]: any = response;
            if (data.password) {
                data.password = "";
                data.repassword = "";
            }

            if (data.birthday) {
                data.birthday = data.birthday.split("/").reverse().join("-");
            }

            this.setState({data, loaded: false});
        }).catch((error: any) => {
            console.log(error);
        });
    }

    get_data = async () => {
        const {user, type}: any = this.props;
        const {data}: any = await Service.getAppService().get_profile(user?._id, type);
        return data;
    }

    thumbnail = (data: any) => {
        if (data?.photo && Object.keys(data?.photo).includes('filename')) {
            return Utils.showImage('students', data, 'photo', true);
        } else {
            return require(`../../assets/${data?.genre || 'default'}.png`).default;
        }
    }

    setHeader = () => {
        return (<>
            <div className={root_style.PageHeader}>
                <Link to='/profile'>
                    <i className='fa-solid fa-arrow-left'/>
                </Link>
                <h1>Editar meus dados</h1>
            </div>
        </>)
    }

    save = async (e: any) => {
        let {data}: any = this.state;
        let {type}: any = this.props;
        if (e) e.preventDefault();
        let remove: any = ['code', 'maritals', 'token', '_category', '_classe'];
        let fields: any = {};
        Object.keys(data).filter((f: any) => !remove.includes(f)).map((f: any) => {
            fields[f] = data[f];
            return f;
        });
        if (fields.password !== "") {
            if (fields.password !== fields.repassword) {
                this.props.setModal({
                    title: 'Senha inválida',
                    message: 'As senhas não conferem!'
                });
                return
            }
        }

        delete fields.repassword;
        fields.type = type;
        await Service.getAppService().save_profile(fields);
        this.props.setModal({
            title: 'Atualizado',
            message: 'Dados atualizados com sucesso!'
        });
    }

    setMask = (type: any, value: any) => {
        if (value === undefined) return '';
        if (type === 'cpf') {
            return value.replace(/\D/g, '').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,2})/, '$1-$2').replace(/(-\d{2})\d+?$/, '$1')
        } else if (type === 'cnpj') {
            return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,2})/, '$1/$2').replace(/(\d{4})(\d{1,2})/, '$1-$2').replace(/(-\d{2})\d+?$/, '$1')
        } else if (type === 'cel') {
            return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2 ').replace(/(\d{4})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,3})\d+?$/, '$1$2');
        } else if (type === 'date') {
            if (value.indexOf('T') !== -1) {
                return value.split('T')[0]
            } else {
                return value
            }
        } else if (type === 'date_input') {
            if (value.indexOf('T') !== -1) {
                return value.split('T')[0].split('-').reverse().join('/')
            } else {
                return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1/$2').replace(/(\d{2})(\d)/, '$1/$2').replace(/(\d{3})(\d{1,2})\d+?$/, '$1');
            }
        } else if (type === 'tel') {
            return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2 ').replace(/(\d{4})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,3})\d+?$/, '$1$2');
        } else if (type === 'checkbox') {
            return !value;
        } else if (type === 'number') {
            return parseFloat(value);
        } else {
            return value;
        }
    }

    setValue = (target: any) => {
        let {data}: any = this.state;
        if (target.name.indexOf(".") !== -1) {
            const [field, value]: any = target.name.split(".");
            if (!data[field]) {
                data[field] = {}
            }
            data[field][value] = target.value;
        } else {
            data[target.name] = target.value;
        }
        this.setState({data});
    }

    filter_fields = (data: any) => {
        return data;
    }

    render() {
        const {loaded, data}: any = this.state;
        const fields: any = this.filter_fields(data);
        return (<>
            <Layout {...this.props} loaded={loaded} header={this.setHeader()}>
                <>
                    <div className={style.ProfileMain}>
                        <div>Mantenha suas informações pessoais corretas e atualizadas para uma comunicação eficiente e
                            receber as informações importantes da escola.
                        </div>
                        <form method='posts' onSubmit={(e: any) => this.save(e)}>
                            <label>
                                <small>Dados pessoais</small>
                            </label>
                            <section>
                                <hgroup>
                                    <label>Nome</label>
                                    <input type='text' name='name' placeholder='Nome do pai' value={fields?.name} onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Data Nascimento</label>
                                    <input type='date' name='birthday' value={fields?.birthday?.slice(0, 10)} onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>CPF</label>
                                    <input type='text' name='cpf' value={fields?.cpf} onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>RG</label>
                                    <input type='text' name='rg' value={fields?.rg} onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Estado civil</label>
                                    {data?.maritals && <select name='marital_status' value={fields?.marital_status} onChange={(e: any) => this.setValue(e.target)}> {data?.maritals.map((mat: any, m: any) => <option key={m}>{mat}</option>)}
                                    </select>}
                                    <label>Endereço</label>
                                    <textarea name='address' value={fields?.address} onChange={(e: any) => this.setValue(e.target)}/>
                                </hgroup>
                            </section>

                            <label>
                                <small>Dados profissionais</small>
                            </label>
                            <section>
                                <hgroup>
                                    <label>E-mail:</label>
                                    <input type='email' value={data?.email} name='email' onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Telefone</label>
                                    <input type='text' value={fields?.phone} name='phone' onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Escolaidade</label>
                                    <input type='text' value={fields?.education} name='education' onChange={(e: any) => this.setValue(e.target)}/>
                                </hgroup>
                            </section>

                            <label>
                                <small>Dados de segurança</small>
                            </label>
                            <section>
                                <hgroup>
                                    <label>Senha</label>
                                    <input type='password' value={fields?.password} name='password' onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Repita a senha</label>
                                    <input type='password' value={fields?.repassword} name='repassword' onChange={(e: any) => this.setValue(e.target)}/>
                                </hgroup>
                            </section>
                            <section>
                                <button type='submit'>SALVAR</button>
                            </section>
                        </form>
                    </div>
                </>
            </Layout>
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);