import React from 'react';
import Layout from "../../layout";
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import {Service} from "../../api";
import style from "./style.module.scss";
import {Utils} from "../../api/utils";
import {UserContext} from "../../context/UserContext";
import root_style from "../styles/layout.module.scss";
import {Link} from "react-router-dom";

class Page extends React.Component<any, any> {
    static contextType = UserContext;
    state = {
        loaded: true,
        terms: false,
        data: []
    }

    componentDidMount() {
        Promise.all([this.get_data()]).then((response: any) => {
            const data: any = response[0];
            console.log(data);
            this.setState({data, loaded: false});
        }).catch((error: any) => {
            console.log(error);
        });
    }

    get_data = async () => {
        const {_id}: any = this.props?.user;
        const {data}: any = await Service.getAppService().get_behavior(_id);
        return data;
    }

    setHeader = () => {
        return (<>
            <div className={root_style.PageHeader}>
                <Link to='/profile'>
                    <i className='fa-solid fa-arrow-left'/>
                </Link>
                <h1>Comportamentos</h1>
            </div>
        </>)
    }

    render() {
        const {loaded, data}: any = this.state;
        return (<>
            <Layout {...this.props} loaded={loaded} header={this.setHeader()}>
                <>
                    <div className={style.ProfileMain}>
                        {data.length > 0 && <>
                            <div>Aqui você terá acesso ao registro completo de suas atitudes, valorizando seu engajamento e contribuição positiva para a comunidade escolar.</div>
                            <ul className={style.Behaviors}>
                                {data.map((beh: any, b: any) => <li key={b}>
                                    <figure>
                                        {beh?.emoji?.emoji}
                                        <strong>{Utils.formateDateString(beh?.createAt).slice(0, 5)}</strong>
                                    </figure>
                                    <div>
                                        {beh?.note}
                                    </div>
                                </li>)}
                            </ul>
                        </>}
                        {data.length <= 0 && <div className={style.BehaviorEmpty}>
                            <i className='fa fa-face-smile-wink'/>
                            <label>Nenhum comportamento registrado hoje!</label>
                        </div>}
                    </div>
                </>
            </Layout>
        </>);
    }
}

const
    mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)

(
    Page
);