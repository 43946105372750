/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React from 'react';
import {Redirect, Route} from 'react-router-dom';

/**
 * COMPONENT AUTHENTICATE SESSION
 * @param Component
 * @param auth
 * @param rest
 */
// @ts-ignore
const AuthSecurity = ({component: Component, auth, ...rest}): any => {
    return (<Route {...rest} render={(props) => (auth === true ? <Component {...props} /> : <Redirect to='/login'/>)}/>);
}

export default AuthSecurity;