import React from 'react';
import Layout from "../../layout";
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import {Service} from "../../api";
import {Utils} from "../../api/utils";
import {Link} from "react-router-dom";
import style from "./style.module.scss";
import root_style from "../styles/layout.module.scss";

class Page extends React.Component<any, any> {

    state = {
        informative: null,
        loaded: true
    }

    componentDidMount() {
        Promise.all([this.get_data()]).then((response: any) => {
            const [get_data]: any = response;
            const {informative}: any = get_data;
            this.setState({informative, loaded: false});
        }).catch((error: any) => {
            console.log(error);
        });
    }

    get_data = async () => {
        const {data}: any = await Service.getAppService().informative(this.props.match.params.id);
        return data;
    }

    setHeader = () => {
        return (<>
            <div className={root_style.PageHeader}>
                <Link to='/informative'>
                    <i className='fa-solid fa-arrow-left'/>
                </Link>
                <h1>Informativo</h1>
            </div>
        </>)
    }

    render() {
        const {informative}: any = this.state;
        const {loaded}: any = this.state;
        return (<>
            <Layout {...this.props} header={this.setHeader()} loaded={loaded}>
                {informative && <>
                    <div className={style.InformativeView}>
                        <time><i className='fa fa-calendar-day'/> {Utils.formateDateString(informative?.createAt)}</time>
                        <strong>{informative?.name}</strong>
                        <img src={Utils.showImage('informative', informative, 'photo')} alt={informative?.name}/>
                        <span dangerouslySetInnerHTML={{__html: informative?.text}}/>
                    </div>
                </>}
            </Layout>
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);