class Storage {

    static set(name: any, value: any) {
        localStorage.setItem(name, JSON.stringify(value));
    }

    static get(name: any) {
        const _name: any = localStorage.getItem(name);
        return JSON.parse(_name);
    }

    static del(name: any) {
        localStorage.removeItem(name);
    }

    static exists(name: any, cb: any) {
        try {
            return Storage.get(name) || cb;
        } catch (error: any) {
            return null;
        }
    }

    static clear() {
        localStorage.clear();
    }
}

class Remote {

    static set(name: any, value: any) {
        sessionStorage.setItem(name, JSON.stringify(value));
    }

    static get(name: any) {
        try {
            const _name: any = sessionStorage.getItem(name);
            return JSON.parse(_name);
        } catch (error: any) {
            return null
        }
    }

    static del(name: any) {
        sessionStorage.removeItem(name);
    }

    static exists(name: any, cb: any) {
        try {
            return Remote.get(name) || cb;
        } catch (error: any) {
            return null;
        }
    }

    static clear() {
        sessionStorage.clear();
    }
}

export {Storage, Remote}
