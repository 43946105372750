import React from 'react';
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import Validate from "../../api/utils/Validate";
import style from "./style.module.scss";
import {Service} from "../../api";
import {Remote} from "../../api/utils";
import {Link} from "react-router-dom";
import Logo from "../../components/Logo";

class Page extends React.Component<any, any> {

    state = {
        open: false,
        result: {},
        fields: {
            code: '',
            password: '',
            repassword: '',
            email: ''
        },
        token: null,
        email: null,
    }

    form: any = React.createRef();

    componentDidMount() {
        if (this.props?.match?.params?.token) {
            const {token}: any = this.props?.match?.params;
            this.setState({token});
        }
    }

    recovery = (e: any) => {
        e.preventDefault();
        let {token, fields}: any = this.state;
        if (!Validate.Form(this.form.current)) return false;

        if (token) {
            delete fields.email;
            fields = {
                ...fields,
                token
            }

            if (fields.repassword !== fields.password) {
                this.props.setModal({
                    title: 'Senhas invalidas',
                    message: 'As senhas não conferem, verifique e tente novamente',
                    buttons: [
                        {
                            label: 'OK, Entendi',
                            callback: (props: any) => {
                                this.setState({send: false});
                                props.closeModal();
                                fields.password = '';
                                fields.repassword = '';
                                this.setState({fields})
                            }
                        }
                    ]
                });
                return;
            }

        } else {
            fields = {email: fields.email}
        }

        Promise.all([Service.getAppService().recovery(fields)]).then((queue: any) => {
            let [response]: any = queue;
            let {data}: any = response;
            this.props.setModal({
                ...data,
                buttons: [
                    {
                        label: 'Ok, Entendi',
                        callback: (props: any) => {
                            Remote.del('email');
                            props.closeModal();
                            if (token) {
                                this.props.history.push('/login');
                            }
                        }
                    }
                ]
            });
            this.setState({fields: {email: ''}, send: false});
        });
    }

    setValue = (target: any) => {
        const {fields}: any = this.state;
        fields[target.name] = target.value;
        this.setState({fields});
    }

    render() {
        const {token, fields}: any = this.state;
        const {type}: any = this.props;
        const {appVersion}: any = global;
        return (<>

                <div className={style.Login}>
                    <header>
                        <Logo/>
                    </header>
                    <main>
                        <form onSubmit={(e: any) => this.recovery(e)}>
                            <fieldset ref={this.form}>

                                {!token && <div>
                                    <label>{type === 'aluno' ? 'Matrícula/E-mail' : 'E-mail'}</label>
                                    <input value={fields.email} name={'email'}
                                           placeholder={type === 'aluno' ? 'Matrícula / E-mail responsavel.' : 'Informe seu e-mail'}
                                           id='name' required onChange={(e: any) => this.setValue(e.target)}/>
                                </div>}

                                {token && <>
                                    <div>
                                        <label>Código de autenticação</label>
                                        <input name='code' placeholder='Informe o código de autenticação'
                                               required={true} autoFocus={true} value={fields.code}
                                               onChange={(e) => this.setValue(e.target)}/>
                                    </div>
                                    <div>
                                        <label>Nova Senha</label>
                                        <input name='password' placeholder='******' type='password' required={true}
                                               autoFocus={true} value={fields.password}
                                               onChange={(e) => this.setValue(e.target)}/>
                                    </div>
                                    <div>
                                        <label>Confirma Senha</label>
                                        <input name='repassword' placeholder='******' type='password' required={true}
                                               autoFocus={true} value={fields.repassword}
                                               onChange={(e) => this.setValue(e.target)}/>
                                    </div>
                                </>}

                                <hgroup>
                                    <button>{token ? 'Salvar' : 'Enviar solicitação'}<i
                                        className={`fa-solid fa-${token ? 'check' : 'envelope'}`}/></button>
                                    <Link to='/'>Voltar para login <i className='fa-solid fa-arrow-left'/></Link>
                                </hgroup>
                            </fieldset>
                        </form>
                    </main>
                    <footer>
                        <span>v{appVersion}</span>
                    </footer>
                    <picture/>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);