/**
 * ROUTE DYNAMICS
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

import {connect} from "react-redux";
import * as appActionCreators from "./store/actions/appActionCreators";
import {Storage} from "./api/utils";
/**/
import AuthRouter from './components/auth';
import Recovery from './pages/Login/recovery';
import Informative from './pages/Informative';
import Profile from './pages/Profile';
import ProfileForm from './pages/Profile/form';
import ProfileFormTeacher from './pages/Profile/form_teacher';
import ProfileContract from './pages/Profile/contract';
import ProfileCard from './pages/Profile/card';
import ProfileFrequency from './pages/Profile/frequency';
import ProfileAssessment from './pages/Profile/assessment';
import ProfileBehavior from './pages/Profile/behavior';
import Finance from './pages/Finance';
import InformativeView from './pages/Informative/view';
import Birthday from './pages/Birthday';
import Report from './pages/Report';
import ReportAssessment from './pages/Report/assessment';
import Behavior from './pages/Behavior';
import Message from './pages/Message';
import Notfound from './pages/Notfound';
import Home from "./pages/Home";
import Login from "./pages/Login";
import Intro from "./pages/Intro";

/**
 * ROUTES SYSTEM
 */
class Routes extends Component<any, any> {

    state = {
        skipper: false
    }

    componentDidMount() {
        const skipper: any = Storage.exists('skipper', false);
        this.setState({skipper});
    }

    render() {
        const {type, auth}: any = this.props;
        const {skipper}: any = this.state;
        return (
            <Router>
                <Switch>
                    <Route path='/' exact component={!!auth ? Home : skipper ? Login : Intro}/>
                    <Route path='/Login' exact component={skipper ? Login : Intro}/>
                    <Route path='/login/recovery' exact component={Recovery}/>
                    <Route path='/login/recovery/:token' exact component={Recovery}/>

                    <AuthRouter path='/home' exact component={Home} auth={!!auth}/>
                    <AuthRouter path='/informative' exact component={Informative} auth={!!auth}/>
                    <AuthRouter path='/informative/:id' exact component={InformativeView} auth={!!auth}/>
                    <AuthRouter path='/profile' exact component={Profile} auth={!!auth}/>
                    <AuthRouter path='/profile/:id' exact component={type === 'aluno' ? ProfileForm : ProfileFormTeacher} auth={!!auth}/>
                    <AuthRouter path='/profile/contract/:id' exact component={ProfileContract} auth={!!auth}/>
                    <AuthRouter path='/profile/behavior/:id' exact component={ProfileBehavior} auth={!!auth}/>
                    <AuthRouter path='/assessment' exact component={ProfileAssessment} auth={!!auth}/>
                    <AuthRouter path='/card' exact component={ProfileCard} auth={!!auth}/>
                    <AuthRouter path='/frequency' exact component={ProfileFrequency} auth={!!auth}/>
                    <AuthRouter path='/finance' exact component={Finance} auth={!!auth}/>
                    <AuthRouter path='/birthday' exact component={Birthday} auth={!!auth}/>
                    <AuthRouter path='/behavior' exact component={Behavior} auth={!!auth}/>
                    <AuthRouter path='/behavior/:id?' exact component={Behavior} auth={!!auth}/>
                    <AuthRouter path='/message' exact component={Message} auth={!!auth}/>
                    <AuthRouter path='/report' exact component={Report} auth={!!auth}/>
                    <AuthRouter path='/report/assessment' exact component={ReportAssessment} auth={!!auth}/>

                    <Route path='*' exact component={Notfound}/>
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Routes);