import React from "react";
import {connect} from "react-redux";
import * as appActionCreators from "./store/actions/appActionCreators";

class Skt extends React.Component<any, any> {

    state = {
        connected: false
    }
    componentDidMount() {
        try {
            const {entity}: any = this.props;
            if (entity) {

                let {socket}: any = this.props;
                if (!socket) {
                    //@ts-ignore
                    socket = global.socket;
                }

                this.setState({connected: true});
            }
        } catch (error: any) {
            this.setState({connected: false});
        }
    }

    render() {
        const {connected}: any = this.state;
        const {children}: any = this.props;
        return (connected && <>{children}</>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Skt);