import React from 'react';
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import style_default from "../../pages/styles/layout.module.scss";
import {UserContext} from "../../context/UserContext";
import {Storage, Utils} from "../../api/utils";
import {Service} from "../../api";
import {NavLink, Redirect} from "react-router-dom";
import Loader from "../../components/Loader";

export class Props {
    children?: any;
    header?: any;
    footer?: any;
    scroll?: any;
    no_padding?: any;
    setUser?: any;
}

class Interface extends React.Component<Props, any> {
    static contextType = UserContext
    state = {
        user: null
    }

    componentDidMount() {
        let padding_value: any = '20px';
        if (this.props.no_padding) {
            padding_value = '0px';
        }
        document.documentElement.style.setProperty('--padding_header', padding_value);
        Promise.all([this.get_me()]).then((response: any) => {
            let {user}: any = this.props;
            const [get_user]: any = response;
            const {me}: any = get_user;
            delete get_user.me;
            user = {
                ...user,
                ...me,
                ...get_user,
            }
            this.props.setUser(user);
        }).catch((error: any) => {
            console.log(error);
        });
    }

    get_me = async () => {
        const {user, type}: any = this.props;
        const {data}: any = await Service.getAppService().get_me(user?._id, type);
        return data;
    }

    logout = () => {
        const {setModal, setUser, setAuth}: any = this.props;
        setModal({
            title: 'Sair do Aplicativo',
            message: 'Deseja encerra a sessão agora?',
            buttons: [
                {
                    label: 'Sim',
                    callback: (props: any) => {
                        Storage.del('token');
                        setUser(null);
                        setAuth(false);
                        props.closeModal();
                        return (<Redirect to='/'/>);
                    }
                },
                {
                    label: 'Não',
                    close: true
                },
            ]
        });
    }
    thumbnail = (data: any) => {
        if (data?.photo && Object.keys(data?.photo).includes('filename')) {
            return Utils.showImage('students', data, 'photo', true);
        } else {
            return require(`../../assets/${data?.genre || 'default'}.png`).default;
        }
    }
    get_name = (user: any) => {
        const {type}: any = this.props;
        if (type === 'aluno') {
            let explode_name: any = user?.name?.split(' ');
            explode_name = explode_name?.filter((n: any) => n.length > 2);
            return explode_name?.slice(0, 2).join(' ').toUpperCase();
        } else {
            return user?.username?.split('.').join(" ").toUpperCase();
        }
    }

    render() {
        const {type, user}: any = this.props;
        const {children, header, footer, scroll, loaded}: any = this.props;
        return (<>
                {loaded && <Loader/>}
                {!loaded && <div className={style_default.PageMain}>
                    <header>
                        {header && <>{header}</>}
                        {!header && <>
                            <figure style={{backgroundImage: `url(${this.thumbnail(user)})`}}/>
                            <div>
                                <strong>Olá, {this.get_name(user)}</strong>
                                {type === 'aluno' && <>
                                    <small>{user?.responsible}</small>
                                    <span>
                                    <small><b>Série: </b>{user?.serie}</small>
                                    <small><b>Turno: </b>{user?.shift}</small>
                                    <small><b>Classe: </b>{user?.classe}</small>
                                    </span>
                                </>}
                                {type === 'professor' && <>
                                    <small>{user?.education}</small>
                                    <span>
                                        <small><b>Série: </b>{user?.classe}</small>
                                        <small><b>Turnos: </b>{user?.period?.join(", ")}</small>
                                    </span>
                                </>}
                            </div>
                        </>}
                    </header>
                    <main style={{overflowY: scroll ? 'auto' : 'hidden'}}>
                        {children && React.cloneElement(children, {...this.props, ...this.state})}
                    </main>
                    <footer>
                        {footer && <>{footer}</>}
                        {!footer && <>
                            <nav data-type={type}>
                                <NavLink to='/home'><i className="fa fa-house"/></NavLink>
                                <NavLink to='/message'><i className="fa fa-message"/></NavLink>
                                <NavLink to='/profile'><i className="fa fa-user-circle"/></NavLink>
                                <NavLink to='/informative'><i className="fa fa-newspaper"/></NavLink>
                                <span onClick={() => this.logout()} title='Sair'><i className='fa fa-sign-out'/></span>
                            </nav>
                        </>}
                    </footer>
                </div>}
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Interface);