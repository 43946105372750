import React from 'react';
import Default from './Default';

import * as appActionCreators from "../store/actions/appActionCreators";
import {connect} from "react-redux";

export class Props {
    children?: any;
    header?: any;
    footer?: any;
    scroll?: any;
    loaded?: any;
    no_padding?: any;
}

class Layout extends React.Component<Props, any> {

    componentDidMount() {

    }

    render() {
        return (<>
                <Default
                    children={this.props.children}
                    header={this.props.header}
                    scroll={this.props.scroll}
                    footer={this.props.footer}
                    loaded={this.props.loaded}
                    no_padding={this.props.no_padding}
                    {...this.props}/>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Layout);