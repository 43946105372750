import React from 'react'

export const UserContext = React.createContext({});

export class UserProvider extends React.Component<any, any> {

    state = {
        user: null,
        curriculum: null,
        behaviors: [],
        installments: [],
        behavior_day: null
    };

    componentDidMount() {
        let {value} = this.props;

        let user: any = {
            ...value,
            curriculum: null,
            behaviors: [],
            behavior_day: null
        }
        this.setState({user});
    }

    render() {
        const {user}: any = this.state;
        const {children}: any = this.props;
        const set_user = (user: any) => this.setState({user});
        return (
            <UserContext.Provider value={{user, set_user}}>
                {children}
            </UserContext.Provider>
        );
    }
}
