import React from 'react';
import Layout from "../../layout";
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import {Service} from "../../api";
import style from "./style.module.scss";
import {Utils} from "../../api/utils";
import {UserContext} from "../../context/UserContext";
import root_style from "../styles/layout.module.scss";
import {Link} from "react-router-dom";

class Page extends React.Component<any, any> {
    static contextType = UserContext;
    state = {
        loaded: true,
        mes: 1,
        terms: false,
        data: false
    }

    componentDidMount() {
        Promise.all([this.get_card()]).then((response: any) => {
            const {data}: any = response[0];
            const get_month: any = new Date();
            this.setState({data: data, loaded: false, mes: (get_month.getMonth() + 1)});
        }).catch((error: any) => {
            console.log(error);
        });
    }

    get_card = async () => {
        const {_id}: any = this.props?.user;
        const {data}: any = await Service.getAppService().get_card(_id);
        return data;
    }

    thumbnail = (data: any) => {
        if (data?.photo && Object.keys(data?.photo).includes('filename')) {
            return Utils.showImage('students', data, 'photo', true);
        } else {
            return require(`../../assets/${data?.genre || 'default'}.png`).default;
        }
    }
    sum_total_lack = (s: any, p: any) => {
        const {data, mes}: any = this.state;
        let currente_date: any = new Date(Utils.getDateTimeCurrent());
        let get_year: any = currente_date.getFullYear().toString();
        let get_day: any = mes <= 9 ? `0${mes}` : (mes);
        let mouth: any = `${get_year}-${get_day.toString()}`;
        return data?.frequency.filter((f: any) => f?.date === mouth && f?.subject === s?._id && f?.presence === p).length || 0;
    }

    show_presence = (p: any) => {
        const {data, mes}: any = this.state;
        if (!data) return 0;
        let currente_date: any = new Date(Utils.getDateTimeCurrent());
        let get_year: any = currente_date.getFullYear().toString();
        let get_day: any = mes <= 9 ? `0${mes}` : (mes);
        let mouth: any = `${get_year}-${get_day.toString()}`;
        return data?.frequency.filter((f: any) => f?.date === mouth && f?.presence === p).length || 0;
    }

    setHeader = () => {
        return (<>
            <div className={root_style.PageHeader}>
                <Link to='/'>
                    <i className='fa-solid fa-arrow-left'/>
                </Link>
                <h1>Frequência do aluno</h1>
            </div>
        </>)
    }

    render() {
        const {loaded, data, mes}: any = this.state;
        return (<>
            <Layout {...this.props} loaded={loaded} header={this.setHeader()}>
                <>
                    <div className={style.ProfileMain}>
                        <div>Garantindo seu comprometimento com o aprendizado e o caminho para o sucesso acadêmico</div>
                        <fieldset className={style.Meses}>
                            <button type='button' data-active={mes === 1} onClick={() => this.setState({mes: 1})}>Jan</button>
                            <button type='button' data-active={mes === 2} onClick={() => this.setState({mes: 2})}>Fev</button>
                            <button type='button' data-active={mes === 3} onClick={() => this.setState({mes: 3})}>Mar</button>
                            <button type='button' data-active={mes === 4} onClick={() => this.setState({mes: 4})}>Abr</button>
                            <button type='button' data-active={mes === 5} onClick={() => this.setState({mes: 5})}>Mai</button>
                            <button type='button' data-active={mes === 6} onClick={() => this.setState({mes: 6})}>Jun</button>
                            <button type='button' data-active={mes === 7} onClick={() => this.setState({mes: 7})}>Jul</button>
                            <button type='button' data-active={mes === 8} onClick={() => this.setState({mes: 8})}>Ago</button>
                            <button type='button' data-active={mes === 9} onClick={() => this.setState({mes: 9})}>Set</button>
                            <button type='button' data-active={mes === 10} onClick={() => this.setState({mes: 10})}>Out</button>
                            <button type='button' data-active={mes === 11} onClick={() => this.setState({mes: 11})}>Nov</button>
                            <button type='button' data-active={mes === 12} onClick={() => this.setState({mes: 12})}>Dez</button>
                        </fieldset>
                        {data && <ul className={style.Frequency}>
                            <li>
                                <strong>Disciplinas</strong>
                                <span>F</span>
                                <span>P</span>
                            </li>
                            {data?.subjects.map((sub: any, x: any) => <li key={x}>
                                <strong>{sub?.name}</strong>
                                <span>{this.sum_total_lack(sub, false)}</span>
                                <span>{this.sum_total_lack(sub, true)}</span>
                            </li>)}
                        </ul>}
                    </div>
                </>
            </Layout>
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);