/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import axios from 'axios';
import {Storage, Utils} from '../utils';

/**
 * SERVICE ABSTRACT
 */
export default class AbstractService {

    name: any;
    response: any;
    domain: any;
    protocol: any;
    adapter: any;
    port: number = 3333;
    url: any;

    /**
     * CONSTRUCTOR
     */
    constructor() {

        this.domain = window.location.hostname;
        this.protocol = window.location.protocol;

        /**
         * SETTING CONFIGURATION AXIOS
         */
        this.adapter = axios.create({
            baseURL: `${Utils.getEnvironment('api')}`,
            timeout: 60000,
            headers: {
                get: {'Content-Type': 'application/json'},
                post: {'Content-Type': 'multipart/form-data'},
                put: {'Content-Type': 'multipart/form-data'},
                delete: {'Content-Type': 'application/json'}
            }
        });

        /**
         * REQUEST INTERCEPTORS
         */
        this.adapter.interceptors.request.use((config: any) => {
            let token = process.env.REACT_APP_TOKEN
            config.headers.Authorization = `Bearer ${token}`;
            config.headers.Entity = this.getEntityHost();
            if (Storage.exists('store', false)) {
                config.headers.Store = Storage.get('store');
            }
            return config;
        }, (error: any) => {
            if (error.message === 'Network Error') {
                return;
            }
            return Promise.reject(error);
        });

        /**
         * RESPONSE INTERCEPTORS
         */
        this.adapter.interceptors.response.use((response: any) => {
            return response;
        }, (error: any) => {
            if (error.message === 'Network Error') {
                return;
            }
            return error.response;
        });
    }

    /**
     * GET ENTITY URL
     * @returns {*}
     */
    getEntityHost() {
        return document.location.hostname;
    }

    /**
     * GET ADAPTER
     * @returns AxiosInstance
     */
    getAdapter() {
        return this.adapter;
    }

    /**
     * GET STORAGE
     * @returns {{prototype: Storage, new(): Storage}}
     */
    getStorage() {
        return Storage;
    }

    /**
     * GET URL BY NAME
     * @returns {string}
     */
    getUrl() {
        return this.url = `${this.name}`;
    }

    /**
     * LIST ALL DOCUMENTS BY PAGINATION
     * @param params
     * @returns AxiosResponse
     */
    async list(params: any) {
        return await this.getAdapter().get(this.getUrl(), {params});
    }

    /**
     * LIST ALL DOCUMENTS
     * @param params
     * @returns AxiosResponse
     */
    async all(params: any) {
        return await this.getAdapter().get(`${this.getUrl()}/all`);
    }

    /**
     * GET DOCUMENT BY ID
     * @param id
     * @returns AxiosResponse
     */
    async get(id: any) {
        return await this.getAdapter().get(this.getUrl(), {params: {id}});
    }

    /**
     * SAVE AND UPDATE DOCUMENT
     * @param data
     */
    async save(data: any) {
        if (data._id) {
            this.response = await this.getAdapter().put(this.getUrl(), this.getData(data));
        } else {
            this.response = await this.getAdapter().post(this.getUrl(), this.getData(data));
        }
        return this.response;
    }

    /**
     * DELETE DOCUMENT BY ID
     * @param id
     * @returns AxiosResponse
     */
    async remove(id: any) {
        return await this.getAdapter().delete(this.getUrl(), {params: {id}});
    }

    /**
     * LIST PARAMETER BY NAME
     * @returns
     */
    async select(params: any) {
        return await this.getAdapter().get(`${this.getUrl()}/select`, {params});
    }

    /**
     * CHECK FILE IN DATA CHANGE POST FOR FORMADATA
     * @param data
     * @returns
     */
    getData(data: any) {
        if (data.file) {
            let formData = new FormData();
            for (let key in data) {
                if (data[`${key}`] instanceof Object && !Array.isArray(data[`${key}`])) {
                    Object.keys(data[`${key}`]).map(i => formData.append(`${key}[${i}]`, data[key][i]));
                } else if (Array.isArray(data[`${key}`])) {
                    Object.values(data[key]).forEach((value: any) => formData.append(key + '[]', value))
                } else {
                    formData.append(key, data[`${key}`]);
                }
            }
            formData.append('file', data.file);
            return formData;
        } else {
            return data;
        }
    }
}