import React from 'react';
import Layout from "../../layout";
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import {Service} from "../../api";
import {UserContext} from "../../context/UserContext";
import {Link} from "react-router-dom";
import {Utils} from "../../api/utils";
import style from "./style.module.scss";
import root_style from "../styles/layout.module.scss";
import CurrentDate from "../../api/utils/Date"
import jsPDF from "jspdf";
import Logo from "../../components/Logo";

class Page extends React.Component<any, any> {

    state = {
        installments: [],
        loaded: true,
        current_month: null
    }

    static contextType = UserContext
    months: any = React.createRef();
    download_voucher = () => {
        const {entity}: any = this.props;
        const {user}: any = this.context;
        const unit: any = "px";
        const size: any = "A4"; // Use A1, A2, A3 or A4
        const orientation: any = "portrait"; // portrait or landscape
        const voucher: any = this.get_voucher();
        const doc: any = new jsPDF(orientation, unit, size)

        doc.addImage(Utils.showImage('entities', entity, 'logo'), "PNG", 25, 30, 190, 100);

        doc.setFont("helvetica", "bold");
        doc.setFontSize(13);
        doc.text("Comprovante de Mensalidade Escolar", 30, 160);
        doc.setFont("helvetica", "normal");
        doc.setFontSize(11);
        doc.text(`Data: ${Utils.formateDateString(this.get_current_installment()?.due_date)}`, 30, (170));
        doc.text(`Aluno: ${user?.name}`, 30, (180));
        doc.text(`Responsável Financeiro: ${user?.responsible}`, 30, (190));
        doc.text(`Matrícula: ${user?.register}`, 30, (200));
        doc.text(`Turma: ${user?.serie}`, 30, (210));
        doc.text(`Valor: ${Utils.formatValue(this.get_current_installment()?.amount)}`, 30, (220));
        doc.text(`Período: ${this.get_current_installment()?.month}/${Utils.getYearForDate(this.get_current_installment()?.due_date)}`, 30, (230));

        doc.setFont("helvetica", "bold");
        doc.setFontSize(13);
        doc.text("Detalhes do Pagamento", 30, 250);
        doc.setFont("helvetica", "normal");
        doc.setFontSize(11);
        doc.text(`Valor Pago: ${Utils.formatValue(this.get_current_installment()?.amount)}`, 30, (260));
        doc.text(`Data de Pagamento: ${Utils.formateDateString(this.get_current_installment()?.payment_date)}`, 30, (270));
        var message: any = doc.splitTextToSize(voucher?.model.replace(/<\/?[^>]+(>|$)/g, ""), 350);
        doc.text(message, 30, (295));
        doc.text(`Atenciosamente, `, 30, (340));
        doc.addImage(Utils.showImage('contracts', voucher, 'signature'), "PNG", 25, 340, 150, 50);

        doc.text(user?.unit_responsible, 30, (400));
        doc.text(user?.unit_name, 30, (410));
        doc.text(user?.unit_address, 30, (420));
        doc.text(user?.unit_contact, 30, (430));
        doc.text(user?.unit_email, 30, (440));

        let voucher_date: any = new Date();
        voucher_date = voucher_date.toISOString()
        voucher_date = voucher_date.slice(0, 10).replace('\-\g', '_')
        doc.save(`COMPROVANTE_${voucher_date}`);
    }

    componentDidMount() {
        Promise.all([this.get_data()]).then((response: any) => {
            const [get_data]: any = response;
            let {installments}: any = get_data;
            installments = installments.map((m: any) => {
                m.month = m.due_date.slice(5, 7);
                return m;
            })
            const current_month: any = CurrentDate?.slice(5, 7);
            this.setState({installments, loaded: false, current_month});
        }).catch((error: any) => {
            console.log(error);
        });
    }

    get_data = async () => {
        let {user}: any = this.props;
        const {data}: any = await Service.getAppService().get_installments(user?._id);
        return data;
    }

    get_voucher = () => {
        const {user}: any = this.props;
        if (!user) return null;
        const {documents}: any = user;
        if (!documents) return null;
        return documents.find((v: any) => v.type === 'voucher');
    }

    setHeader = () => {

        return (<>
            <div className={root_style.PageHeader}>
                <Link to='/'>
                    <i className='fa-solid fa-arrow-left'/>
                </Link>
                <h1>Financeiro</h1>
            </div>
        </>)
    }
    get_month = (date: any) => {
        return new Date(date).toLocaleDateString('pt-BR', {month: 'short'}).toUpperCase().slice(0, 3);
    }
    get_expired_days = (installment: any, check: any = false) => {
        let current_date: any = new Date(CurrentDate);
        let due_date: any = new Date(installment?.due_date);
        let diffmonth: any = (current_date - due_date);
        let diffdays: any = diffmonth / (1000 * 60 * 60 * 24);
        if (check) {
            return diffdays > 0;
        } else {
            return diffdays < 0 ? false : parseInt(diffdays);
        }
    }
    get_expired = (installment: any) => {
        return 0;
    }
    check_amount_delay_value = (install: any) => {
        return install?.amount;
    }
    get_due_date = (date: any) => {
        if (!date?.payment_date) return false;
        let get_date: any = date?.payment_date.split('T');
        return `${Utils.formateDate(get_date[0])} às ${get_date[1].slice(0, 5)}`
    }

    sum_value = (installments: any) => {
        if (installments.length <= 0) return 0;
        let sum: any = installments.filter((f: any) => f.status).map((m: any) => m?.amount)
        sum = sum.reduce((a: any, b: any) => (a + b), 0);
        return Utils.formatValue(sum || 0);
    }
    get_installments = () => {
        const {installments}: any = this.state;
        const month: any = CurrentDate.slice(5, 7);
        return installments.filter((f: any) => f.due_date.slice(5, 7) <= month);
    }

    set_active_month = (day: any, pos: any) => {
        const {months}: any = this;
        const months_list: any = months.current.querySelector('ol');
        const last_month: any = months_list.querySelector(`[data-day="${day}"]`);
        const position: any = ((months.clientWidth / 2) - ((last_month.clientWidth * pos) / 2) - (last_month.clientWidth / 2));
        months_list.style.transform = `translateX(-${position}px)`;
        this.setState({current_month: day});
    }

    get_current_installment(): any {
        const {current_month, installments}: any = this.state;
        return installments.find((i: any) => i.month === current_month);
    }

    render() {
        const {loaded, current_month, installments}: any = this.state;
        return (<>
            <Layout {...this.props} loaded={loaded} header={this.setHeader()}>
                <>
                    {<div className={style.Installments}>
                        <div>Aqui, oferecemos comodidade, transparência e facilidade no acompanhamento e pagamento das mensalidades.</div>
                        <section ref={this.months}>
                            {this.get_installments().length > 0 && <ol>
                                {this.get_installments()?.map((install: any, i: any) => <li key={i} data-active={install?.due_date?.slice(5, 7) === current_month} data-day={install?.due_date?.slice(5, 7)} onClick={() => this.set_active_month(install?.due_date?.slice(5, 7), i)}>
                                    <strong>{this.get_month(install?.due_date?.slice(0, 10))}</strong>
                                    <small>{install?.due_date?.slice(8, 10)}</small>
                                    <small>{install?.due_date?.slice(0, 4)}</small>
                                </li>)}
                            </ol>}
                        </section>
                        <span>
                            <hgroup>
                                <label><strong>Pagas: </strong> {installments.filter((f: any) => f.status === 1).length}</label>
                                <label><strong>Em aberto: </strong>{installments.filter((f: any) => f.status === 0).length}</label>
                                <label><strong>Vencidas: </strong>{installments.filter((f: any) => (this.get_expired_days(f, true) && f?.status === 0)).length}</label>
                            </hgroup>
                            <hgroup>
                                <div>valor total</div>
                                <h2>{this.sum_value(installments)}</h2>
                            </hgroup>
                        </span>
                        <article>
                            <label data-paid={this.get_current_installment()?.status === 1}>
                                {(!this.get_expired_days(this.get_current_installment(), true) && this.get_current_installment()?.status === 0) &&
                                    <>
                                        Aguardando pagamento
                                    </>}
                                {(this.get_current_installment()?.status === 1) &&
                                    <>
                                        Mensalidade paga
                                    </>}
                                {(this.get_expired_days(this.get_current_installment(), true) && this.get_current_installment()?.status === 0) &&
                                    <>
                                        Mensalidade em atraso à {this.get_expired_days(this.get_current_installment())} Dias
                                    </>}
                            </label>
                            <h1>{Utils.formatValue(this.get_current_installment()?.amount)}</h1>
                            <em>Vencimento em {this.get_current_installment()?.due_date.slice(0, 10).split('-').reverse().join('/')}</em>
                            {(this.get_expired_days(this.get_current_installment(), true) && this.get_current_installment()?.status === 0) && <div>Regularize o pagamento imediatamente para evitar interrupção dos serviços educacionais.</div>}
                            {(!this.get_expired_days(this.get_current_installment(), true) && this.get_current_installment()?.status === 0) && <div>Por favor, efetue o pagamento o quanto antes para evitar atrasos e garantir a continuidade dos serviços educacionais.</div>}
                            {this.get_current_installment()?.status === 1 && <>
                                <div>Mensalidade quitada! Agradecemos seu compromisso e apoio contínuo à educação.</div>
                                <code><i className='fa fa-calendar'/> Pago em {this.get_due_date(this.get_current_installment()) || '00/00/0000'}</code>
                                <button onClick={() => this.download_voucher()}><i className='fa fa-receipt'/> Baixar comprovante</button>
                            </>}

                        </article>
                    </div>}
                </>
            </Layout>
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);