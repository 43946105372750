import React from 'react';
import Layout from "../../layout";
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import {Service} from "../../api";
import root_style from "../styles/layout.module.scss";
import {Link} from "react-router-dom";
import {Utils} from "../../api/utils";
import style from "./style.module.scss";

class Page extends React.Component<any, any> {

    state = {
        birthdays: [],
        loaded: true
    }

    componentDidMount() {
        Promise.all([this.get_data()]).then((response: any) => {
            const [get_data]: any = response;
            let {birthdays}: any = get_data;
            this.setState({birthdays, loaded: false});
        }).catch((error: any) => {
            console.log(error);
        });
    }

    get_data = async () => {
        const {data}: any = await Service.getAppService().get_data();
        return data;
    }

    setHeader = () => {
        return (<>
            <div className={root_style.PageHeader}>
                <Link to='/'>
                    <i className='fa-solid fa-arrow-left'/>
                </Link>
                <h1>Aniversariantes</h1>
            </div>
        </>)
    }

    thumbnail = (data: any) => {
        if (Object.keys(data).includes('photo')) {
            return Utils.showImage('students', data, 'photo');
        } else {
            return require(`../../assets/${data?.genre || 'default'}.png`).default;
        }
    }

    render() {
        const {loaded, birthdays}: any = this.state;
        return (<>
            <Layout {...this.props} header={this.setHeader()} scroll loaded={loaded}>
                <>
                    <div className={style.Birthdays}>
                        {birthdays.length > 0 && <ul>
                            <span>Parabéns aos aniversariantes deste mês, que sejam dias especiais e memoráveis em suas vidas</span>
                            {birthdays.map((birth: any, b: any) => <li key={b}>
                                <figure style={{backgroundImage: `url(${this.thumbnail(birth)})`}}/>
                                <div>
                                    <strong>{birth?.name}</strong>
                                    <span>Nasc.: {Utils.formateDateString(birth?.birthday)}</span>
                                    <span>Turma/Turno.: {birth?.serie}</span>
                                </div>
                            </li>)}
                        </ul>}
                    </div>
                </>
            </Layout>
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);