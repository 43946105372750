import React from 'react';
import Layout from "../../layout";
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import {Service} from "../../api";
import style from "./style.module.scss";
import {Utils} from "../../api/utils";
import {UserContext} from "../../context/UserContext";
import root_style from "../styles/layout.module.scss";
import {Link} from "react-router-dom";

class Page extends React.Component<any, any> {
    static contextType = UserContext;
    state = {
        loaded: true,
        data: {}
    }

    componentDidMount() {
        Promise.all([this.get_data()]).then((response: any) => {
            const [data]: any = response;
            data.password = "";
            data.repassword = "";
            this.setState({data, loaded: false});
        }).catch((error: any) => {
            console.log(error);
        });
    }

    get_data = async () => {
        const {user, type}: any = this.props;
        const {data}: any = await Service.getAppService().get_profile(user?._id, type);
        return data;
    }

    thumbnail = (data: any) => {
        if (data?.photo && Object.keys(data?.photo).includes('filename')) {
            return Utils.showImage('students', data, 'photo', true);
        } else {
            return require(`../../assets/${data?.genre || 'default'}.png`).default;
        }
    }

    setHeader = () => {
        return (<>
            <div className={root_style.PageHeader}>
                <Link to='/profile'>
                    <i className='fa-solid fa-arrow-left'/>
                </Link>
                <h1>Editar meus dados</h1>
            </div>
        </>)
    }

    save = async (e: any) => {
        let {data}: any = this.state;
        if (e) e.preventDefault();
        let get_fields: any = ['_id', 'birthday', 'naturalness', 'nationality', 'address', 'responsible_name', 'responsible_email', 'responsible_phone', 'emergency_contact_name', 'emergency_contact_phone', 'affiliation', 'password', 'repassword', 'name'];
        let fields: any = {};
        get_fields.map((f: any) => {
            fields[f] = data[f];
            return f;
        })

        if (fields.password !== "") {
            if (fields.password !== fields.repassword) {
                this.props.setModal({
                    title: 'Senha inválida',
                    message: 'As senhas não conferem!'
                });
                return
            }
        }
        delete fields.repassword;
        fields.type = this.props.type;
        await Service.getAppService().save_profile(fields);
        this.props.setModal({
            title: 'Atualizado',
            message: 'Dados atualizados com sucesso!'
        });
    }

    setMask = (type: any, value: any) => {
        if (value === undefined) return '';
        if (type === 'cpf') {
            return value.replace(/\D/g, '').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,2})/, '$1-$2').replace(/(-\d{2})\d+?$/, '$1')
        } else if (type === 'cnpj') {
            return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,2})/, '$1/$2').replace(/(\d{4})(\d{1,2})/, '$1-$2').replace(/(-\d{2})\d+?$/, '$1')
        } else if (type === 'cel') {
            return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2 ').replace(/(\d{4})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,3})\d+?$/, '$1$2');
        } else if (type === 'date') {
            if (value.indexOf('T') !== -1) {
                return value.split('T')[0]
            } else {
                return value
            }
        } else if (type === 'date_input') {
            if (value.indexOf('T') !== -1) {
                return value.split('T')[0].split('-').reverse().join('/')
            } else {
                return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1/$2').replace(/(\d{2})(\d)/, '$1/$2').replace(/(\d{3})(\d{1,2})\d+?$/, '$1');
            }
        } else if (type === 'tel') {
            return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2 ').replace(/(\d{4})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,3})\d+?$/, '$1$2');
        } else if (type === 'checkbox') {
            return !value;
        } else if (type === 'number') {
            return parseFloat(value);
        } else {
            return value;
        }
    }

    setValue = (target: any) => {
        let {data}: any = this.state;
        if (target.name.indexOf(".") !== -1) {
            const [field, value]: any = target.name.split(".");
            if (!data[field]) {
                data[field] = {}
            }
            data[field][value] = target.value;
        } else {
            data[target.name] = target.value;
        }
        this.setState({data});
    }

    filter_fields = (data: any) => {
        return data;
    }

    render() {
        const {loaded, data}: any = this.state;
        const fields: any = this.filter_fields(data);
        return (<>
            <Layout {...this.props} loaded={loaded} header={this.setHeader()}>
                <>
                    <div className={style.ProfileMain}>
                        <div>Mantenha suas informações pessoais corretas e atualizadas para uma comunicação eficiente e
                            receber as informações importantes da escola.
                        </div>
                        <form method='posts' onSubmit={(e: any) => this.save(e)}>
                            <label>
                                <small>Dados do Aluno</small>
                            </label>
                            <section>
                                <hgroup>
                                    <label>Nome</label>
                                    <input type='text' name='name' placeholder='Nome' value={fields?.name} onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Filiação</label>
                                    <input type='text' name='affiliation.father' placeholder='Nome do pai' value={fields?.affiliation?.father} onChange={(e: any) => this.setValue(e.target)}/>
                                    <input type='text' name='affiliation.mother' placeholder='Nome da mãe' value={fields?.affiliation?.mother} onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Data Nascimento</label>
                                    <input type='date' name='birthday' value={fields?.birthday?.slice(0, 10)} onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Naturalidade</label>
                                    <input type='text' name='naturalness' value={fields?.naturalness} onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Nacionalidade</label>
                                    <input type='text' name='nationality' value={fields?.nationality} onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Endereço</label>
                                    <textarea name='address' value={fields?.address} onChange={(e: any) => this.setValue(e.target)}/>
                                </hgroup>
                            </section>
                            <section>
                                <hgroup>
                                    <label>Filiação</label>
                                    <input type='text' name='affiliation.father' placeholder='Nome do pai'
                                           value={fields?.affiliation?.father}
                                           onChange={(e: any) => this.setValue(e.target)}/>
                                    <input type='text' name='affiliation.mother' placeholder='Nome da mãe'
                                           value={fields?.affiliation?.mother}
                                           onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Data Nascimento</label>
                                    <input type='date' name='birthday' value={fields?.birthday?.slice(0, 10)}
                                           onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Naturalidade</label>
                                    <input type='text' name='naturalness' value={fields?.naturalness}
                                           onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Nacionalidade</label>
                                    <input type='text' name='nationality' value={fields?.nationality}
                                           onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Endereço</label>
                                    <textarea name='address' value={fields?.address}
                                              onChange={(e: any) => this.setValue(e.target)}/>
                                </hgroup>
                            </section>

                            <label>
                                <small>Dados do Responsável</small>
                            </label>
                            <section>
                                <hgroup>
                                    <label>Nome</label>
                                    <input type='text' value={fields?.responsible_name} name='responsible_name'
                                           onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>E-mail:</label>
                                    <input type='email' value={data?.responsible_email} name='responsible_email'
                                           onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Contato</label>
                                    <input type='cel' value={this.setMask('cel', fields?.responsible_phone)}
                                           name='responsible_phone' onChange={(e: any) => this.setValue(e.target)}/>
                                </hgroup>
                            </section>

                            <label>
                                <small>Em caso de emergência, entrar em contato com</small>
                            </label>
                            <section>
                                <hgroup>
                                    <label>Nome</label>
                                    <input type='text' value={fields?.emergency_contact_name}
                                           name='emergency_contact_name'
                                           onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Contato</label>
                                    <input type='cel' value={this.setMask('cel', fields?.emergency_contact_phone)}
                                           name='emergency_contact_phone'
                                           onChange={(e: any) => this.setValue(e.target)}/>
                                </hgroup>
                            </section>

                            <label>
                                <small>Dados de segurança</small>
                            </label>
                            <section>
                                <hgroup>
                                    <label>Senha</label>
                                    <input type='password' value={fields?.password} name='password'
                                           onChange={(e: any) => this.setValue(e.target)}/>
                                    <label>Repita a senha</label>
                                    <input type='password' value={fields?.repassword} name='repassword'
                                           onChange={(e: any) => this.setValue(e.target)}/>
                                </hgroup>
                            </section>
                            <section>
                                <button type='submit'>SALVAR</button>
                            </section>
                        </form>
                    </div>
                </>
            </Layout>
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);