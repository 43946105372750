import React from 'react'
import style from './style.module.scss';

import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import Intro1 from "../../assets/intro/1.png"
import Intro2 from "../../assets/intro/2.png"
import Intro3 from "../../assets/intro/3.png"
import Intro4 from "../../assets/intro/4.png"
import Intro5 from "../../assets/intro/5.png"
import Intro6 from "../../assets/intro/6.png"
import {Storage} from "../../api/utils";

class Intro extends React.Component<any, any> {

    data = [
        {
            image: Intro1,
            type: "aluno",
            title: "Bem vindo",
            message: "O APP de acesso para pais do Educandário Tia Jade."
        },
        {
            image: Intro2,
            type: "aluno",
            title: "Tudo na Mão",
            message: "O APP permite que os alunos acessem informações sobre suas aulas, tarefas, notas e comunicados da escola."
        },
        {
            image: Intro3,
            type: "aluno",
            title: "Acompanhamento",
            message: "Além disso, o APP também permite que os pais ou responsáveis dos alunos acompanhem o desempenho escolar e a presença de seus filhos na escola."
        },
        {
            image: Intro4,
            type: "professor",
            title: "Bem vindo",
            message: "Professor(a)! Estamos felizes em tê-lo(a) em nosso app, onde você encontrará as ferramentas necessárias para transformar a experiência educacional dos seus alunos. Vamos juntos construir um ambiente de aprendizado inovador e enriquecedor!"
        },
        {
            image: Intro5,
            type: "professor",
            title: "Comportamento do aluno",
            message: "Acompanhe de perto o progresso dos seus alunos com a funcionalidade de comportamento do aluno no nosso aplicativo, permitindo que os professores registrem e compartilhem informações valiosas sobre o desempenho e engajamento diário dos estudantes na escola."

        },
        {
            image: Intro6,
            type: "professor",
            title: "Chamada online",
            message: "Facilite a aprendizagem remota com a funcionalidade de chamada online, conectando alunos e professores em tempo real para uma experiência educacional envolvente e interativa."
        }
    ];

    state = {
        step: 0
    }

    intro: any = React.createRef();

    componentDidMount() {
        if (Storage.exists('skipper', false)) {
            this.props.history.push('/');
        }
    }

    skipper = () => {
        Storage.set('skipper', true);
        window.location.reload();
    }

    get_data = () => {
        let {type}: any = this.props;
        return this.data.filter((f: any) => f.type === type);
    }

    navigate = (dir: any) => {
        let {step}: any = this.state;
        if (dir === 'prev') {
            step--;
        } else {
            step++;
        }
        if (step === this.get_data().length) {
            this.skipper();
            return;
        }
        this.setState({step});
    }

    render() {
        const {step}: any = this.state;
        return (<>
                <div className={style.Intro}>
                    <div>
                        <button onClick={() => this.skipper()}>PULAR</button>
                    </div>
                    <ul ref={this.intro} style={{transform: `translateX(-${(100 * step)}vw)`}}>
                        {this.get_data().map((item: any, x: any) => <li key={x}>
                            <figure>
                                <img src={item?.image} alt={item?.title}/>
                            </figure>
                            <h1>{item?.title}</h1>
                            <span>{item?.message}</span>
                        </li>)}
                    </ul>
                    <hgroup>
                        <button disabled={step === 0} onClick={() => this.navigate('prev')}><i
                            className='fa-solid fa-arrow-left fa-lg'/></button>
                        <div>{this.get_data().map((item: any, c: any) => <b key={c} data-active={step === c}/>)}</div>
                        <button onClick={() => this.navigate('next')}><i
                            className={`fa-solid fa-${step === (this.get_data().length - 1) ? 'check' : 'arrow-right'} fa-lg`}/>
                        </button>
                    </hgroup>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Intro);