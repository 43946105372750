import React from 'react';
import Layout from "../../layout";
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import {Service} from "../../api";
import style from "./style.module.scss";
import {Utils} from "../../api/utils";
import {UserContext} from "../../context/UserContext";
import root_style from "../styles/layout.module.scss";
import {Link} from "react-router-dom";

class Page extends React.Component<any, any> {
    static contextType = UserContext;
    state = {
        loaded: true,
        bim: 1,
        terms: false,
        data: false
    }

    componentDidMount() {
        Promise.all([this.get_card()]).then((response: any) => {
            const {data}: any = response[0];
            let get_month: any = new Date();
            get_month = (get_month.getMonth() + 1);
            this.setState({data: data, loaded: false, bim: Math.ceil(get_month / 3)});
        }).catch((error: any) => {
            console.log(error);
        });
    }

    get_card = async () => {
        const {_id}: any = this.props?.user;
        const {data}: any = await Service.getAppService().get_card(_id);
        return data;
    }

    thumbnail = (data: any) => {
        if (data?.photo && Object.keys(data?.photo).includes('filename')) {
            return Utils.showImage('students', data, 'photo', true);
        } else {
            return require(`../../assets/${data?.genre || 'default'}.png`).default;
        }
    }

    setHeader = () => {
        return (<>
            <div className={root_style.PageHeader}>
                <Link to='/'>
                    <i className='fa-solid fa-arrow-left'/>
                </Link>
                <h1>Boletim escolar</h1>
            </div>
        </>)
    }

    render() {
        const {loaded, data, bim}: any = this.state;
        return (<>
            <Layout {...this.props} loaded={loaded} header={this.setHeader()}>
                <>
                    <div className={style.ProfileMain}>
                        <div>Aqui você encontrará suas notas e terá uma visão clara do seu progresso educacional.</div>
                        <fieldset className={style.Bims}>
                            <button type='button' data-active={bim === 1} onClick={() => this.setState({bim: 1})}>1º BIM</button>
                            <button type='button' data-active={bim === 2} onClick={() => this.setState({bim: 2})}>2º BIM</button>
                            <button type='button' data-active={bim === 3} onClick={() => this.setState({bim: 3})}>3º BIM</button>
                            <button type='button' data-active={bim === 4} onClick={() => this.setState({bim: 4})}>4º BIM</button>
                        </fieldset>
                        <hgroup className={style.Subjects}>
                            {data && <ul>
                                <li>
                                    <strong>Disciplinas</strong>
                                    <span>Nota</span>
                                </li>
                                {data?.subjects.map((sub: any, x: any) => <li key={x}>
                                    <strong>{sub?.name}</strong>
                                    <span>{data.reports[`bim_${bim}_${sub?._id}`] || 0}</span>
                                </li>)}
                            </ul>}
                        </hgroup>
                    </div>
                </>
            </Layout>
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);