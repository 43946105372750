import {createSlice} from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import {Storage} from "../../api/utils";

export const app = createSlice({
    name: 'app',
    initialState: {
        socket: {},
        auth: !!Storage.get('token'),
        entity: null,
        user: Storage.exists('token', null) ? jwtDecode(Storage.exists('token', null)) : null,
        loading: true,
        modal: null,
        type: null,
    },
    reducers: {
        setData: (state: any, action: any) => {
            return {...state, data: action.payload};
        }, setSocket: (state: any, action: any) => {
            return {...state, socket: action.payload};
        }, setEntity: (state: any, action: any) => {
            return {...state, entity: action.payload};
        }, setAuth: (state: any, action: any) => {
            return {...state, auth: action.payload};
        }, setUser: (state: any, action: any) => {
            return {...state, user: action.payload};
        }, setLoading: (state: any, action: any) => {
            return {...state, loading: action.payload};
        }, setModal: (state: any, action: any) => {
            return {...state, modal: action?.payload};
        }, setType: (state: any, action: any) => {
            return {...state, type: action?.payload};
        }, PwaInit: (state: any, action: any) => {
            return {...state, registration: action.payload};
        }, PwaUpdate: (state: any, action: any) => {
            return {...state, registration: action.payload};
        }
    }
});

const {actions, reducer} = app;
export const {
    setData,
    setSocket,
    setEntity,
    setAuth,
    setUser,
    setModal,
    setType,
    setLoading,
    PwaInit,
    PwaUpdate
} = actions;
export default reducer;