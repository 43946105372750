import React from 'react';
import Layout from "../../layout";
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import {Service} from "../../api";
import style from "./style.module.scss";
import {Utils} from "../../api/utils";
import {UserContext} from "../../context/UserContext";
import root_style from "../styles/layout.module.scss";
import {Link} from "react-router-dom";
import Logo from "../../components/Logo";
import jsPDF from "jspdf";

class Page extends React.Component<any, any> {
    static contextType = UserContext;
    state = {
        loaded: true,
        terms: false,
        data: false,
    }

    contract: any = React.createRef();

    componentDidMount() {
        this.setState({loaded: false});
    }

    setHeader = () => {
        return (<>
            <div className={root_style.PageHeader}>
                <Link to='/profile'>
                    <i className='fa-solid fa-arrow-left'/>
                </Link>
                <h1>Contrato escolar</h1>
            </div>
        </>)
    }

    save = async () => {
        let {terms, data}: any = this.state;
        if (!terms) {
            this.props.setModal({
                title: 'Termos',
                message: 'Aceite os termos do contrato'
            });
            return
        }
        let get_fields: any = ['_id', 'terms'];
        let fields: any = {};
        get_fields.map((f: any) => {
            fields[f] = data[f];
            return f;
        });

        await Service.getAppService().save_profile(fields);
        this.props.setModal({
            title: 'Atualizado',
            message: 'Dados atualizados com sucesso!'
        });
    }

    setValue = (target: any) => {
        let {documents}: any = this.state;
        documents[target.name] = target.value;
        this.setState({documents});
    }

    set_terms = () => {

        this.props.setModal({
            title: 'Aceitar termos',
            message: 'Desejar aceitar os termos desse contrato?!',
            buttons: [
                {
                    label: 'Não',
                    callback: async (props: any) => {
                        this.setState({terms: false});
                        props.closeModal();
                    }
                },
                {
                    label: 'Sim',
                    callback: async () => {
                        let {data}: any = this.state;
                        const {user}: any = this.context;
                        let fields: any = {
                            _id: user?._id,
                            terms: true
                        };
                        await Service.getAppService().save_profile(fields);
                        this.props.setModal({
                            title: 'Atualizado',
                            message: 'Dados atualizados com sucesso!'
                        });
                        data.terms = true;
                        this.setState({terms: true, data});
                    },
                }
            ]
        });
    }

    get_contract = (user: any) => {
        if (!user) return null;
        const {documents}: any = user;
        if (!documents) return null;
        return documents.find((v: any) => v.type === 'contract');
    }

    download_contract = () => {
        const {user}: any = this.context;
        const unit: any = "px";
        const size: any = "A4"; // Use A1, A2, A3 or A4
        const orientation: any = "portrait"; // portrait or landscape
        const doc: any = new jsPDF(orientation, unit, size);
        let contract: any = this.contract.current.cloneNode(true);
        contract.style.width = `680px`;

        doc.html(contract, {
            callback: (doc: any) => {
                doc.save(`CONTRATO_${Utils.noAccents(user?.name, "_").toUpperCase()}`);
            },
            autoPaging: 'text',
            margin: [30, 30, 30, 30],
            html2canvas: {
                scale: 0.6
            }
        });
    }

    render() {
        const {terms, loaded}: any = this.state;
        const {user}: any = this.props;
        const data: any = this.get_contract(user);
        console.log(user);
        return (<>
            <Layout {...this.props} loaded={loaded} header={this.setHeader()}>
                <>
                    <div className={style.ProfileMain} ref={this.contract}>
                        <figure><Logo/></figure>
                        <div className={style.Contact} dangerouslySetInnerHTML={{__html: data?.model}}/>
                        {!terms && <div className={style.ContactTerms}><input disabled={user?.terms} checked={terms || user?.terms} onChange={() => this.set_terms()} type="checkbox"/> Eu li e concordo com os termos do contrato.</div>}
                    </div>
                    <button onClick={() => this.download_contract()}><i className='fa fa-receipt'/> Baixar contranto</button>
                </>
            </Layout>
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);