import React from 'react';
import Layout from "../../layout";
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import {Service} from "../../api";
import {UserContext} from "../../context/UserContext";
import {Link} from "react-router-dom";
import {Utils} from "../../api/utils";
import style from "./style.module.scss";
import root_style from "../styles/layout.module.scss";
import CurrentDate from "../../api/utils/Date"

class Page extends React.Component<any, any> {

    state = {
        classe: false,
        all: true,
        classeroom: [],
        subjects: [],
        reports: [],
        loaded: true,
        current_month: null
    }

    static contextType = UserContext

    componentDidMount() {
        this.update_data();
    }

    update_data = () => {
        Promise.all([this.get_data()]).then((response: any) => {
            const {classe, classeroom, subjects, reports}: any = response[0];
            this.setState({loaded: false, classe, classeroom, subjects, reports});
        }).catch((error: any) => {
            console.log(error);
        });
    }

    get_data = async () => {
        let {user}: any = this.props;
        const {data}: any = await Service.getAppService().get_report(user?._id);
        return data;
    }
    setHeader = () => {
        return (<>
            <div className={root_style.PageHeader}>
                <Link to='/'>
                    <i className='fa-solid fa-arrow-left'/>
                </Link>
                <h1>Chamada</h1>
            </div>
        </>)
    }

    get_join = (value: any) => {
        return value?.join(", ");
    }

    thumbnail = (data: any) => {
        if (Object.keys(data).includes('photo')) {
            return Utils.showImage('studentdents', data, 'photo');
        } else {
            return require(`../../assets/${data?.genre || 'default'}.png`).default;
        }
    }

    set_presence = (student: any) => {
        let {classeroom}: any = this.state;
        const index: any = classeroom.findIndex((idx: any) => idx._id === student._id);
        classeroom[index].presence = !classeroom[index].presence;
        this.setState({classeroom});
    }

    set_all = () => {
        let {classeroom, all}: any = this.state;
        all = !all;
        classeroom = classeroom.map((s: any) => {
            s.presence = !all;
            return s;
        });
        this.setState({all, classeroom});
    }

    save_report = () => {
        const {setModal}: any = this.props;
        const {user}: any = this.context;
        try {
            let {classeroom, subjects, classe}: any = this.state;
            let reports: any = [];
            classeroom.map((c: any) => {
                subjects.map((s: any) => {
                    let item: any = {
                        presence: c?.presence,
                        student: c?._id,
                        subject: s?._id,
                    }
                    reports.push(item);
                    return s;
                });
                return c;
            });
            const data: any = {
                teacher: user?._id,
                classe: classe?._id,
                reports
            }
            Service.getAppService().report(data).then(() => {
                setModal({message: 'A frequência foi salva com sucesso!'});
                this.update_data();
            });
        } catch (error: any) {
            setModal({title: 'Error', message: error.message});
        }
    }

    save = () => {
        const {setModal}: any = this.props;
        setModal({
            title: 'Salvar frequência',
            message: 'Deja salvar a frequência de todos os alunos da forma que está agora?',
            buttons: [
                {
                    label: 'Sim',
                    callback: () => this.save_report()
                },
                {
                    label: 'Não',
                    close: true
                }
            ]
        });
    }

    render() {
        const {classe, classeroom, loaded, all, reports}: any = this.state;
        return (<>
            <Layout {...this.props} loaded={loaded} header={this.setHeader()}>
                <>
                    <div className={style.Classe}>
                        <small><b>Turma:</b> {classe?.name}</small>
                        <small><b>Turno:</b> {this.get_join(classe?.period)}</small>
                        <small><b>Horário:</b> {this.get_join(classe?.hours)}</small>
                        <small><b>Dias:</b> {this.get_join(classe?.week)}</small>
                    </div>

                    {reports.length <= 0 && <fieldset className={style.ClasseOption}>
                        <button onClick={() => this.set_all()}>{all ? 'Marcar' : 'Desmarcar'} todos</button>
                    </fieldset>}

                    <div className={style.ClasseRoom}>
                        {reports.length > 0 && <div>
                            <i className='fa fa-smile-wink fa-3x'/>
                            <span>A chamada de hoje já foi realizada!</span>
                        </div>}
                        {reports.length <= 0 && <ul>
                            {classeroom?.map((student: any, s: any) => <li key={s} onClick={() => this.set_presence(student)}>
                                <figure style={{backgroundImage: `url(${this.thumbnail(student)})`}}/>
                                <span>
                                    <b>{student?.name}</b>
                                    <small><b>Matrícula:</b> {student?.register}</small>
                                    <small><b>Nasc:</b> {Utils.formateDateString(student?.birthday)}</small>
                                </span>
                                <i className={`fa fa-${student?.presence ? 'check-' : ''}circle fa-2x`} data-check={student?.presence}/>
                            </li>)}
                        </ul>}
                    </div>
                    {reports.length <= 0 && <div className={style.ClasseFooter}>
                        <button onClick={() => this.save()}>SALVAR</button>
                    </div>}
                </>
            </Layout>
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);