import React from 'react';
import Layout from "../../layout";
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import {UserContext} from "../../context/UserContext";
import style from "./style.module.scss";
import {Service} from "../../api";
import {Utils} from "../../api/utils";
import {Link, NavLink} from "react-router-dom";
import banner_birthday from "../../assets/birthday.png";

class Page extends React.Component<any, any> {
    static contextType = UserContext

    state = {
        informatives: [],
        birthdays: [],
        loaded: true
    }

    componentDidMount() {
        Promise.all([this.get_data()]).then((response: any) => {
            const [get_data]: any = response;
            let {informatives, birthdays}: any = get_data;
            this.setState({informatives, birthdays, loaded: false});
        }).catch((error: any) => {
            console.log(error);
        });
    }

    get_data = async () => {
        const {data}: any = await Service.getAppService().get_data();
        return data;
    }

    check_active = (emoji: any) => {
        let {user}: any = this.props;
        return emoji?.aliases[0] === user?.behavior_day['emoji']?.aliases[0];
    }

    get_menu = () => {
        const {user}: any = this.props;
        const data: any = [
            {to: 'finance', label: 'Financeiro', icon: 'chart-area', type: 'aluno'},
            {to: 'card', label: 'Boletim', icon: 'circle-check', type: 'aluno', assessment: false},
            {to: 'assessment', label: 'Avaliação', icon: 'star', type: 'aluno', assessment: true},
            {to: 'frequency', label: 'Frequência', icon: 'list-check', type: 'aluno'},
            {to: 'report', label: 'Chamada', icon: 'list-check', type: 'professor'},
            {to: 'behavior', label: 'Comportamento', icon: 'smile', type: 'professor'},
            {to: 'message', label: 'Mensagens', icon: 'message', type: 'professor'},
        ];
        const {type}: any = this.props;
        return data.filter((f: any) => f.type === type && (f?.assessment === user?.assessment || f?.assessment === undefined));
    }

    check_register = () => {
        const {user}: any = this.props;
        return ['-', ' ', '', undefined, null].includes(user?.affiliation)
            || ['-', ' ', '', undefined, null].includes(user?.responsible_email)
            || ['-', ' ', '', undefined, null].includes(user?.responsible_phone)
            || ['-', ' ', '', undefined, null].includes(user?.emergency_contact_name)
            || ['-', ' ', '', undefined, null].includes(user?.emergency_contact_phone)
            || user?.terms === 0;
    }

    is_loaded = () => {
        const {type, user}: any = this.props;
        if (!user) return false;
        if (type === 'aluno') {
            return !Object.keys(user).includes('installments');
        } else {
            return !Object.keys(user).includes('education');
        }
    }

    render() {
        const {type, user}: any = this.props;
        const {informatives, loaded, birthdays}: any = this.state;
        return (<>
            {!loaded && <Layout {...this.props} scroll loaded={this.is_loaded()}>
                <>
                    <section>
                        <ul className={style.Menu} data-type={type}>
                            {this.get_menu().map((menu: any, m: any) => <li key={m}>
                                <NavLink to={menu?.to}>
                                    <i className={`fa fa-${menu?.icon}`}/>
                                    <label>{menu?.label}</label>
                                </NavLink>
                            </li>)}

                        </ul>
                    </section>
                    {birthdays.length > 0 && <section className={style.Birthdays}>
                        <NavLink to='/birthday'>
                            <img src={banner_birthday} alt={'Aniversariants do mês'}/>
                        </NavLink>
                    </section>}
                    {this.check_register() && type === 'aluno' && <section>
                        <div className={style.Notifications}>
                            <label>
                                <label>Notificações</label>
                                <hr/>
                            </label>
                            <span>
                                Atenção! Identificamos que há dados incompletos em seu cadastro como aluno.
                            </span>
                            <ul>
                                {['-', ' ', '', undefined, null].includes(user?.affiliation) && <li>
                                    <NavLink to={`/profile/${user?._id}`}>
                                        <i className='fa fa-times'/> <label>Filiação</label><i className='fa fa-arrow-right'/>
                                    </NavLink>
                                </li>}
                                {['-', ' ', '', undefined, null].includes(user?.responsible_email) && <li>
                                    <NavLink to={`/profile/${user?._id}`}>
                                        <i className='fa fa-times'/> <label>E-mail do responsável</label><i className='fa fa-arrow-right'/>
                                    </NavLink>
                                </li>}
                                {['-', ' ', '', undefined, null].includes(user?.responsible_phone) && <li>
                                    <NavLink to={`/profile/${user?._id}`}>
                                        <i className='fa fa-times'/> <label>Conato do responsável</label><i className='fa fa-arrow-right'/>
                                    </NavLink>
                                </li>}
                                {['-', ' ', '', undefined, null].includes(user?.emergency_contact_name) && <li>
                                    <NavLink to={`/profile/${user?._id}`}>
                                        <i className='fa fa-times'/> <label>Nome conato emergência</label><i className='fa fa-arrow-right'/>
                                    </NavLink>
                                </li>}
                                {['-', ' ', '', undefined, null].includes(user?.emergency_contact_phone) && <li>
                                    <NavLink to={`/profile/${user?._id}`}>
                                        <i className='fa fa-times'/> <label>Conato emergência</label><i className='fa fa-arrow-right'/>
                                    </NavLink>
                                </li>}
                                {user?.terms === 0 && <li>
                                    <NavLink to={`/profile/contract/${user?._id}`}>
                                        <i className='fa fa-times'/> <label>Aceitar o termo do contrato escolar</label><i className='fa fa-arrow-right'/>
                                    </NavLink>
                                </li>}
                            </ul>
                        </div>
                    </section>}
                    {user?.behavior_day && <section>
                        <div className={style.Behavior}>
                            <label>
                                <label>Comportamento {new Date(user?.behavior_day.createAt).toLocaleDateString()}</label>
                                <hr/>
                            </label>
                            <ul>
                                {user?.behaviors.map((beh: any, b: any) => <li key={b} data-active={this.check_active(beh?.emoji)} title={beh?.emoji?.emoji}>{beh?.emoji?.emoji}</li>)}
                            </ul>
                            <small>
                                <b>{user?.behaviors.find((e: any) => e.emoji?.aliases[0] === user?.behavior_day?.emoji?.aliases[0])?.name}</b>
                                {user?.behavior_day?.note}
                            </small>
                        </div>
                    </section>}
                    {user?.curriculum?.subject.length > 0 && <section>
                        <div className={style.Classes}>
                            <label>
                                <label>Aulas de {user?.curriculum?.name} </label>
                                <hr/>
                            </label>
                            {user?.curriculum?.subject.length > 0 && <span className={style.Subjects}>
                                {user?.curriculum?.subject.map((sub: any, s: any) =>
                                    <label key={s}>{sub}</label>
                                )}
                            </span>}
                        </div>
                    </section>}
                    {informatives.length > 0 && <section>
                        <div className={style.Informatives}>
                            <label>
                                <label>Informativos</label>
                                <hr/>
                            </label>
                            <ul>
                                {informatives.map((info: any, i: any) => <li key={i}>
                                    <Link to={`/informative/${info?._id}`}>
                                        <figure>
                                            <img src={Utils.showImage('informative', info, 'photo', true)}
                                                 alt={info?.name}/>
                                        </figure>
                                        <span>
                                            <strong>{info?.name}</strong>
                                            <small>{Utils.LimitText(info?.text || '', 20)}</small>
                                        </span>
                                    </Link>
                                </li>)}
                            </ul>
                        </div>
                    </section>}
                </>
            </Layout>}
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);