import React from 'react';
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import style from "./style.module.scss";
import {Storage} from "../../api/utils";
import {Service} from "../../api";
import Validate from "../../api/utils/Validate";
import {UserContext} from "../../context/UserContext";
import Loader from "../../components/Loader";
import {Link, Redirect} from "react-router-dom";
import Logo from "../../components/Logo";

class Page extends React.Component<any, any> {
    static contextType = UserContext
    state = {
        open: false,
        send: false,
        result: {},
        fields: {
            register: '',
            password: ''
        },
    }

    form: any = React.createRef();

    componentDidMount() {
    }

    goto_login = () => {
        this.props.history.push('/login/recovery')
    }

    login = (e: any) => {
        e.preventDefault();
        let {fields}: any = this.state;
        let {setUser, setAuth}: any = this.props;
        if (!Validate.Form(this.form.current)) return false;
        this.setState({send: true});
        const {type}: any = this.props;
        fields.type = type;
        fields.register = fields?.register.toUpperCase();
        Promise.all([Service.getAppService().signin(fields)]).then((queue: any) => {
            let [response]: any = queue;
            let {data}: any = response;
            if (data.status) {
                const {token}: any = data;
                Storage.set("token", token);
                setAuth(true);
                setUser(data?.me);
                return (<Redirect to='/home'/>);
            } else {
                this.props.setModal({
                    ...data,
                });
                this.setState({send: false});
            }
        });
    }

    setValue = (target: any) => {
        const {fields}: any = this.state;
        fields[target.name] = target.value;
        this.setState({fields});
    }

    render() {
        const {fields, send}: any = this.state;
        const {type}: any = this.props;
        const {appVersion}: any = global;

        return (<>
                <div className={style.Login}>
                    <header>
                        <Logo/>
                    </header>

                    <main>
                        <form method='posts' onSubmit={(e: any) => this.login(e)}>
                            {send && <div style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                height: '30vh'
                            }}><Loader size={true}/></div>}
                            {!send && <fieldset ref={this.form}>
                                <div>
                                    <label>{type === 'aluno' ? 'Matrícula/E-mail' : 'Login'}</label>
                                    <input value={fields.register} type='text'
                                           placeholder={type === 'aluno' ? 'Informe a matrícula do aluno.' : 'Informe sua senha'}
                                           name='register' required onChange={(e: any) => this.setValue(e.target)}/>
                                </div>
                                <div>
                                    <label>Senha</label>
                                    <input value={fields.password} type='password'
                                           placeholder='Informe a senha para logar' name='password' required
                                           onChange={(e: any) => this.setValue(e.target)}/>
                                </div>
                                <hgroup>
                                    <button type='submit'>Entrar<i className='fa-solid fa-arrow-right'/></button>
                                    <Link to='/login/recovery'>Esqueci minha <i className='fa-solid fa-key'/></Link>
                                </hgroup>
                            </fieldset>}
                        </form>
                    </main>
                    <footer>
                        <span>v{appVersion}</span>
                    </footer>
                    <picture/>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);