import React from 'react';
import Layout from "../../layout";
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import {Service} from "../../api";
import style from "./style.module.scss";
import {Utils} from "../../api/utils";
import {UserContext} from "../../context/UserContext";
import {NavLink} from "react-router-dom";

class Page extends React.Component<any, any> {
    static contextType = UserContext;
    state = {
        loaded: true,
        data: null
    }

    how_is_scholl = ['A pé', 'Bicicleta', 'Transporte Pùblico', 'Transporte Escolar', 'Carro', 'Outros'];
    breed = ['Branca', 'Negro', 'Parda', 'Amarela', 'Indigina']

    componentDidMount() {
        Promise.all([this.get_data()]).then((response: any) => {
            const [data]: any = response;
            this.setState({data, loaded: false});
        }).catch((error: any) => {
            console.log(error);
        });
    }

    get_data = async () => {
        const {_id}: any = this.props?.user;
        const {type}: any = this.props;
        const {data}: any = await Service.getAppService().get_profile(_id, type);
        return data;
    }

    thumbnail = (data: any) => {
        if (data?.photo && Object.keys(data?.photo).includes('filename')) {
            return Utils.showImage('students', data, 'photo', true);
        } else {
            return require(`../../assets/${data?.genre || 'default'}.png`).default;
        }
    }

    header_teacher = () => {
        const {data}: any = this.state;
        const {type,user}: any = this.props;
        const get_name = () => {
            try {
                let explode_name: any = data?.name?.split(' ');
                explode_name = explode_name.filter((n: any) => n.length > 2);
                return explode_name.slice(0, 2).join(' ').toUpperCase();
            } catch (error: any) {
                return '';
            }
        }

        return (<>
            <header className={style.Header} data-type={type}>
                <div>
                    <strong>
                        {get_name()}
                    </strong>
                    <picture>
                        <figure style={{backgroundImage: `url(${this.thumbnail(user)})`}}>
                            {user?.behavior_day?.emoji && <hgroup>{user?.behavior_day?.emoji?.emoji}</hgroup>}
                        </figure>
                    </picture>
                    <article>
                        <b>{data?._classe?.name.toLocaleUpperCase()}</b>
                        <small>{data?._classe?.period.join(", ")}</small>
                    </article>
                </div>
                <small>{data?.name}</small>

            </header>
        </>);
    }

    header_student = () => {
        const {user}: any = this.props;
        const {data}: any = this.state;
        const get_name = () => {
            try {
                let explode_name: any = data?.name?.split(' ');
                explode_name = explode_name.filter((n: any) => n.length > 2);
                return explode_name.slice(0, 2).join(' ').toUpperCase();
            } catch (error: any) {
                return '';
            }
        }

        return (<>
            <header className={style.Header}>
                <div>
                    <strong>
                        {get_name()}
                    </strong>
                    <picture>
                        <figure style={{backgroundImage: `url(${this.thumbnail(user)})`}}>
                            {user?.behavior_day?.emoji && <hgroup>{user?.behavior_day?.emoji?.emoji}</hgroup>}
                        </figure>
                    </picture>
                    <article>
                        <b>{data?._classe?.name.toLocaleUpperCase()}</b>
                        <small>{data?._period?.name.toLocaleUpperCase()}</small>
                    </article>
                </div>
                <small>{data?.name}</small>
                <span>
                    <b>MAT: {data?.register}</b>
                    <em>{data?.type}</em>
                </span>
            </header>
        </>);
    }

    profile_data_student = (data: any) => {
        return (<>
            <div className={style.ProfileMain}>
                <form>
                    <section>
                        <i className='fa fa-user-graduate'/>
                        <div>
                            <label>Aluno(a)</label>
                            <small>{data?.name}</small>
                        </div>
                    </section>
                    <section>
                        <i className='fa fa-user'/>
                        <div>
                            <label>Responsável</label>
                            <small>{data?.responsible_name}</small>
                        </div>
                    </section>
                    <NavLink to={`/profile/contract/${data?._id}`}>
                        <i className='fa fa-file-text'/>
                        <div>
                            <label>Contrato</label>
                            <small>{data?.responsible_name}</small>
                        </div>
                    </NavLink>
                    <NavLink to={`/profile/behavior/${data?._id}`}>
                        <i className='fa fa-face-smile-wink'/>
                        <div>
                            <label>Comportamentos</label>
                            <small>Confira o registro completo dos comportamentos do aluno.</small>
                        </div>
                    </NavLink>
                    <label>
                        <small>Dados do Aluno(a)</small>

                    </label>
                    <section>
                        <hgroup>
                            <label>Filiação</label>
                            <small>Pai: {data?.affiliation?.father}</small>
                            <small>Mãe: {data?.affiliation?.mother}</small>
                            <label>Data Nascimento</label>
                            <small>{Utils.formateDateString(data?.birthday)}</small>
                            <label>Sexo</label>
                            <small>{data?.genre}</small>
                            <label>Naturalidade</label>
                            <small>{data?.naturalness}</small>
                            <label>Nacionalidade</label>
                            <small>{data?.nationality}</small>
                            <label>Endereço</label>
                            <small>{data?.address}</small>
                            <label>Como o aluno vai à escola</label>
                            <small>{data?.how_is_scholl[0]}</small>

                            {data?.health.includes('Sim') && <>
                                <label>Possui algum problema de saude?</label>
                                <small>{data?.health_detail}</small>
                            </>}
                            {data?.medicine.includes('Sim') && <>
                                <label>O Aluno toma algum medicamento?</label>
                                <small>{data?.medicine_detail}</small>
                            </>}
                            {data?.deficiency.includes('Sim') && <>
                                <label>Possui alguma alergia?</label>
                                <small>{data?.deficiency_detail}</small>
                            </>}
                            {data?.allergy.includes('Sim') && <>
                                <label>Tem alguma deficiência?</label>
                                <small>{data?.allergy_detail}</small>
                            </>}
                        </hgroup>
                    </section>
                    <label>
                        <small>Dados do Responsável</small>
                    </label>
                    <section>
                        <hgroup>
                            <label>Nome</label>
                            <small>{data?.responsible_name}</small>
                            {data?.responsible_email && <>
                                <label>E-mail:</label>
                                <small>{data?.responsible_email}</small>
                            </>}
                            <label>Contato</label>
                            <small>{data?.responsible_phone}</small>
                        </hgroup>
                    </section>
                    <label>
                        <small>Em caso de emergência, entrar em contato com</small>
                    </label>
                    <section>
                        <hgroup>
                            <label>Nome</label>
                            <small>{data?.emergency_contact_name}</small>
                            <label>Contato</label>
                            <small>{data?.emergency_contact_phone}</small>
                        </hgroup>
                    </section>
                    <section>
                        <NavLink to={`/profile/${data?._id}`}>EDITAR DADOS</NavLink>
                    </section>
                </form>
            </div>
        </>)
    }

    profile_data_teacher = (data: any) => {
        return (<>
            <div className={style.ProfileMain}>
                <form>
                    <section>
                        <i className='fa fa-user'/>
                        <div>
                            <label>Professor(a)</label>
                            <small>{data?.name}</small>
                        </div>
                    </section>
                    <section>
                        <i className='fa fa-user-graduate'/>
                        <div>
                            <label>Formação</label>
                            <small>{data?.education}</small>
                        </div>
                    </section>

                    <label>
                        <small>Dados do professor(a)</small>
                    </label>
                    <section>
                        <hgroup>
                            <label>CPF</label>
                            <small>{data?.cpf}</small>
                            <label>RG</label>
                            <small>{data?.rg}</small>
                            <label>Contato</label>
                            <small>{data?.phone}</small>
                            <label>E-mail</label>
                            <small>{data?.email}</small>
                            {data?.birthday && <>
                                <label>Data Nascimento</label>
                                <small>{Utils.formateDateString(data?.birthday)}</small>
                            </>}
                            <label>Sexo</label>
                            <small>{data?.genre}</small>
                            <label>Endereço</label>
                            <small>{data?.address}</small>
                            <label>Estado Cívil</label>
                            <small>{data?.marital_status}</small>
                        </hgroup>
                    </section>
                    <section>
                        <NavLink to={`/profile/${data?._id}`}>EDITAR DADOS</NavLink>
                    </section>
                </form>
            </div>
        </>);
    }

    render() {
        const {loaded, data}: any = this.state;
        const {type}: any = this.props;
        return (<>
            <Layout loaded={loaded} no_padding header={['Vetereno', 'Novato'].includes(data?.type) ? this.header_student() : this.header_teacher()} {...this.props}>
                <>
                    {type === 'aluno' && this.profile_data_student(data)}
                    {type === 'professor' && this.profile_data_teacher(data)}
                </>
            </Layout>
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);