/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */
import React, {Suspense} from 'react';
import Routes from './Routes';
import ClearCache from './ClearCache';
import * as appActionCreators from "./store/actions/appActionCreators";
import {connect} from "react-redux";
import Socket from "./socket";
import {UserProvider} from "./context/UserContext";
import Modal from "./components/Modal";
import {Utils} from "./api/utils";

/**
 * COMPONENT APP
 */
class App extends React.Component<any, any> {
    state = {}

    async componentDidMount() {
        const height: any = global.innerHeight;

        const {entity}: any = this.props;

        const {public_vapidkey}: any = entity;
        Utils.registerNotification(public_vapidkey, (device: any) => {
        }, (error: any) => {
        })
        document.documentElement.style.setProperty(`--height`, `${height}px`);
    }

    render() {

        return (
            <>
                <Suspense fallback={null}>
                    <ClearCache>
                        <Socket {...this.props}>
                            <UserProvider value={this.props.user}>
                                <Modal/>
                                <Routes/>
                            </UserProvider>
                        </Socket>
                    </ClearCache>
                </Suspense>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(App);