/**
 * Author: Thiago Silva
 * Date: 01/06/2021
 */

import {AppService} from './services';

/**
 * CLASS SERVICE
 */
export default class Service {

    /**
     * CREATE STATIC INSTANCE
     */
    static AppService: any;

    /**
     * SERVICE APP
     */
    static getAppService() {
        if (Service.AppService) return Service.AppService;
        Service.AppService = new AppService();
        return Service.AppService;
    }
}