import React from 'react';
import Layout from "../../layout";
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import {Service} from "../../api";
import style from "./style.module.scss";
import {Utils} from "../../api/utils";
import root_style from "../styles/layout.module.scss";
import {Link} from "react-router-dom";

class Page extends React.Component<any, any> {
    state = {
        informatives: [],
        loaded: true
    }

    componentDidMount() {
        Promise.all([this.get_data()]).then((response: any) => {
            const [get_data]: any = response;
            const {informatives}: any = get_data;
            this.setState({informatives, loaded: false});
        }).catch((error: any) => {
            console.log(error);
        });
    }

    get_data = async () => {
        const {data}: any = await Service.getAppService().all_informatives();
        return data;
    }

    setHeader = () => {
        return (<>
            <div className={root_style.PageHeader}>
                <Link to='/'>
                    <i className='fa-solid fa-arrow-left'/>
                </Link>
                <h1>Informativos</h1>
            </div>
        </>)
    }

    render() {
        const {informatives}: any = this.state;
        const {loaded}: any = this.state;
        return (<>
            <Layout {...this.props} header={this.setHeader()} loaded={loaded}>
                <>
                    {informatives.length > 0 && <ul className={style.Informatives}>
                        {informatives.map((info: any, i: any) => <li key={i}>
                            <Link to={`/informative/${info?._id}`}>
                                <figure>
                                    <img src={Utils.showImage('informative', info, 'photo', true)}
                                         alt={info?.name}/>
                                </figure>
                                <span>
                                    <time><i className='fa fa-calendar-day'/> {Utils.formateDateString(info?.createAt)}</time>
                                    <strong>{info?.name}</strong>
                                    <small>{Utils.LimitText(info?.text || '', 10)}</small>
                                </span>
                            </Link>
                        </li>)}
                    </ul>}

                    {informatives.length <= 0 && <div className={style.InformativesEmpty}>
                        <i className='fa fa-newspaper'/>
                        <label>Nenhum informativo no momento!</label>
                    </div>}
                </>
            </Layout>
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);