/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {Component} from 'react';
import {Service} from './api';
import {Storage, Utils} from "./api/utils";
import Loader from "./components/Loader";
import * as appActionCreators from "./store/actions/appActionCreators";
import {connect} from "react-redux";
import style from "./entity.module.scss";
import {io} from "socket.io-client";

class Entities extends Component<any, any> {

    state = {
        data: false,
        cover: false,
        loading: true,
        type: null,
        accept_cookie: false,
        cookie_hidden: false,
        socket: null
    }

    /**
     * GET ENTITY AND ALL DATA FOR DELIVERY
     */
    async componentDidMount() {
        const type: any = Storage.get('type');
        this.setState({type});
        this.init();
    }

    init() {
        try {
            Promise.all([this.getEntity()]).then((queue) => {
                let [entity]: any = queue;
                try {

                    if (!entity?.status) {
                        document.title = `Educar`;
                        this.props.setLoading(false);
                        this.props.setEntity(entity);
                        return;
                    }

                    if (!entity) {
                        this.props.setLoading(false);
                        this.setState({entity: true});
                        if (document.location.pathname !== '/error') {
                            document.location.pathname = 'error';
                        }
                    } else {
                        this.props.setEntity(entity);

                        document.title = `Educar • ${entity?.name || ''}`;
                        document.documentElement.style.setProperty('--primary', entity?.color);
                        document.documentElement.style.setProperty('--secondary', entity?.color_secondary);
                        document.documentElement.style.setProperty(`--primary-over-10`, `${entity?.color}10`);
                        document.documentElement.style.setProperty(`--primary-over-20`, `${entity?.color}20`);

                        if (entity?.plugins?.notify) {
                            Utils.getRegister((device: any) => {
                                if (!device) {
                                    const {public_vapidkey}: any = entity;
                                    Utils.registerNotification(public_vapidkey, (result: any) => this.props.setDevice(result), (error: any) => {
                                        console.log(error);
                                        this.props.setModal({
                                            title: 'Device não registrada',
                                            message: 'Não foi possivel registrar seu device no momento'
                                        })
                                    });
                                } else {
                                    this.props.setDevice(device);
                                }
                            });
                        }

                        const optSocket: any = {
                            transports: ['websocket', 'polling'],
                            query: {entity: entity?._id, app: true}
                        };
                        let socket: any = io(`${Utils.getEnvironment('socket')}`, optSocket);
                        // @ts-ignore
                        global.socket = socket;
                        this.props.setSocket(socket);
                        let {hostname}: any = document.location;
                        hostname = hostname.split(".")[0]
                        this.props.setType(hostname);

                        setTimeout(() => this.props.setLoading(false), 1000);
                    }

                } catch (error: any) {
                    document.title = `Educar • ${entity?.name}`;
                    this.props.setLoading(false);
                }
            }).catch((error: any) => {
                console.log(error);
            });
        } catch (error: any) {
            console.log(error);
        }
    }

    /**
     * GET CURRENT ENTITY
     */
    async getEntity() {
        try {
            const {data} = await Service.getAppService().getEntity();
            return data;
        } catch (error: any) {
            return false;
        }
    }

    render() {
        const {children, loading, entity}: any = this.props;

        return (
            <>
                {loading && <Loader/>}
                {!loading && (entity && !entity?.status) && <div className={style.NotEntity}/>}
                {(!loading && children && (entity && entity?.status)) && React.cloneElement(children, {...this.props, ...this.state})}
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Entities);