/**
 * API SERVICE
 * Author: Thiago Silva
 * CreateAt: 2021-3-22
 */

import AbstractService from '../library/AbstractService';

export default class AppService extends AbstractService {

    constructor() {
        super();
        this.name = 'app';
    }

    async getEntity() {
        return await this.getAdapter().get(`${this.name}/info`);
    }

    async get_data() {
        return await this.getAdapter().get(`${this.name}/load_data`);
    }

    async recovery(data: any) {
        let endpoint: any = `${this.name}/recovery`;
        if (data?.token) {
            endpoint = `${this.name}/password_change`;
        }
        return await this.getAdapter().post(endpoint, data);
    }

    async signin(data: any) {
        return await this.getAdapter().post(`${this.name}/signin`, data);
    }

    async get_me(_id: any, type: any) {
        return await this.getAdapter().get(`${this.name}/me`, {params: {_id, type}});
    }

    async get_installments(id: any) {
        return await this.getAdapter().get(`${this.name}/installments`, {params: {id}});
    }

    async get_report(id: any) {
        return await this.getAdapter().get(`${this.name}/get_report`, {params: {id}});
    }

    async report(data: any) {
        return await this.getAdapter().post(`${this.name}/report`, data);
    }

    async informative(id: any) {
        return await this.getAdapter().get(`${this.name}/informatives`, {params: {id}});
    }

    async get_profile(id: any, type: any) {
        return await this.getAdapter().get(`${this.name}/get_profile`, {params: {id, type}});
    }

    async get_card(id: any) {
        return await this.getAdapter().get(`${this.name}/get_card`, {params: {id}});
    }

    async save_profile(data: any) {
        return await this.getAdapter().put(`${this.name}/save_profile`, data);
    }

    async get_contract(id: any) {
        return await this.getAdapter().get(`${this.name}/get_contract`, {params: {id}});
    }

    async get_behavior(id: any) {
        return await this.getAdapter().get(`${this.name}/get_behavior`, {params: {id}});
    }

    async all_informatives() {
        return await this.getAdapter().get(`${this.name}/informatives`);
    }

    async behavior(data: any) {
        return await this.getAdapter().post(`behaviorstudent`, data);
    }

    async check_behavior(student: any) {
        return await this.getAdapter().get(`${this.name}/check_behavior`, {params: {student}});
    }
}