import React from 'react';
import Layout from "../../layout";
import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";
import {Link, NavLink} from "react-router-dom";
import {Utils} from "../../api/utils";
import style from "./style.module.scss";
import root_style from "../styles/layout.module.scss";
import {Service} from "../../api";
import {combineReducers} from "redux";

class Page extends React.Component<any, any> {

    state = {
        classeroom: [],
        behaviors: [],
        behavior: {},
        student: null,
        loaded: true,
        saved: false,
    }

    note: any = React.createRef();

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        const {id}: any = this.props.match.params;
        if (prevProps.match.params.id !== id) {
            const student: any = this.state.classeroom.find((s: any) => s._id === id);
            this.setState({student});
        }
    }

    componentDidMount() {
        Promise.all([this.get_data()]).then((response: any) => {
            const [get_data]: any = response;
            let {classeroom, behaviors}: any = get_data;
            const {id}: any = this.props.match.params;
            const student: any = classeroom.find((s: any) => s._id === id);
            this.setState({student});
            this.setState({classeroom, behaviors, loaded: false, student});
        }).catch((error: any) => {
            console.log(error);
        });
    }

    get_data = async () => {
        let {user}: any = this.props;
        const {data}: any = await Service.getAppService().get_report(user?._id);
        return data;
    }

    check_behavior = async (id: any) => {
        const {data}: any = await Service.getAppService().check_behavior(id);
        return data;
    }

    setHeader = () => {
        const {student}: any = this.state;
        return (<>
            <div className={root_style.PageHeader}>
                <Link to={`${student ? '/behavior' : '/'}`}>
                    <i className='fa-solid fa-arrow-left'/>
                </Link>
                <h1>Comportamentos</h1>
            </div>
        </>)
    }

    thumbnail = (data: any) => {
        if (Object.keys(data).includes('photo')) {
            return Utils.showImage('students', data, 'photo');
        } else {
            return require(`../../assets/${data?.genre || 'default'}.png`).default;
        }
    }

    set_behavior = (student: any) => {
        this.props.history.push(`/behavior/${student?._id}`)
        // Promise.all([this.check_behavior(student?._id)]).then((result: any) => {
        //     console.log(result);
        //
        // }).catch((error: any) => {
        //     console.log(error);
        // });
    }

    set_behav_value = (target: any) => {
        let {behavior}: any = this.state;
        behavior[target.name] = target.value;
        this.setState({behavior});
    }
    set_emoji = (emoji: any) => {
        let {behavior}: any = this.state;
        behavior['emoji'] = emoji;
        this.setState({behavior});
    }
    save_behavior = () => {
        let {behavior, student}: any = this.state;
        let {setModal}: any = this.props;
        if (!Object.keys(behavior).includes('emoji')) {
            setModal({
                title: 'Comportamento',
                message: 'Selecione um emoji para salvar o comportamento de hoje do aluno(a)'
            })
            return;
        }
        behavior['student'] = student?._id;
        setModal({
            title: 'Salvar comportamento',
            message: `Deseja salvar agora o comportamento?`,
            buttons: [
                {
                    label: 'Sim',
                    callback: async (props: any) => {
                        setModal({icon: 'wait', message: 'Aguarde...', buttons: []})
                        let {behavior}: any = this.state;
                        Service.getAppService().behavior(behavior).then((response: any) => {
                            const {data}: any = response;
                            if (response.status === 200) {
                                let behavior = {
                                    emoji: null,
                                    note: '',
                                    student: '',
                                };
                                this.note.current.value = '';
                                this.setState({behavior, saved: true});
                                setModal(false);
                            } else {
                                setModal({...data})
                            }

                        }).catch((error: any) => {
                            const {data}: any = error;
                            setModal({...data})
                        });
                    }
                },
                {
                    label: 'Não',
                    close: true
                }
            ]
        });
    }
    check_active = (emoji: any) => {
        let {behavior}: any = this.state;
        if (!behavior) return false;
        return emoji?.aliases[0] === behavior['emoji']?.aliases[0];
    }

    render() {
        const {loaded, classeroom, behaviors, student, saved}: any = this.state;
        return (<>
            <Layout {...this.props} header={this.setHeader()} loaded={loaded}>
                <>
                    {student && <>
                        {saved && <div className={style.Saved}>
                            <i className='fa fa-face-grin-wink'/>
                            <span>O comportamento para o aluno(a), <b>{student?.name}</b> foi registrado com sucesso. <br/><br/>Só poderá ser registrado um novo comportamento amanhã!</span>
                            <NavLink to='/behavior'><i className='fa fa-arrow-left'/> Volta aos alunos</NavLink>
                        </div>}
                        {!saved && <div className={style.Behaviors}>
                            {!student && <span>Clique sobre um aluno(a) para informar o seu comportamento diário, todos comportamento serão disponibilizados no acesso do aluno.</span>}

                            {student && <span>
                                <strong>{student?.name}</strong>
                                <span><b>Matrícula: </b> {student?.register}</span>
                            </span>}

                            {behaviors && <ul>
                                {behaviors.map((behav: any, b: any) => <li key={b} onClick={() => this.set_emoji(behav?.emoji)} data-active={this.check_active(behav.emoji)}>
                                    <figure>{behav?.emoji?.emoji}</figure>
                                    <span>
                                        <b>{behav?.name}</b>
                                        <small>{behav?.description}</small>
                                    </span>
                                </li>)}
                            </ul>}

                            <fieldset>
                                <label>Informe alguma nota referente ao dia.</label>
                                <textarea rows={3} name='note' ref={this.note} onChange={(e: any) => this.set_behav_value(e.target)}/>
                                <hgroup>
                                    <button onClick={() => this.setState({student: null, behavior: {}})}>
                                        <span>CANCERLAR</span>
                                        <i className='fa fa-times'/>
                                    </button>
                                    <button onClick={() => this.save_behavior()}>
                                        <span>SALVAR</span>
                                        <i className='fa fa-check'/>
                                    </button>
                                </hgroup>
                            </fieldset>
                        </div>}
                    </>}
                    {!student && <>
                        <span>Clique sobre um aluno(a) para informar o seu comportamento diário, todos comportamento serão disponibilizados no acesso do aluno.</span>
                        <div className={style.Students}>
                            {classeroom.length > 0 && <ul>
                                {classeroom.map((stud: any, b: any) => <li key={b} onClick={() => this.set_behavior(stud)}>
                                    <figure style={{backgroundImage: `url(${this.thumbnail(stud)})`}}/>
                                    <div>
                                        <strong>{stud?.name}</strong>
                                        <small><b>Matrícula:</b> {stud?.register}</small>
                                    </div>
                                    <i className='fa fa-arrow-right'/>
                                </li>)}
                            </ul>}
                        </div>
                    </>}
                </>
            </Layout>
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(Page);