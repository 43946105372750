/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import {configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import appReduce from "./actions/appActionCreators";

export default configureStore({
    reducer: {
        app: appReduce
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
});