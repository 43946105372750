/**
 * CONTROLLER ABSTRACT
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import bcrypt from 'bcryptjs';

export default class Utils {

    /**
     * FORMAT TEXT CAPITALIZE
     * @param name
     */
    static ucFirst = (name: any) => {
        return name.charAt(0).toUpperCase() + name.slice(1)
    }

    /**
     * CREATE PASSWORD ENCRYPTING
     * @param password
     * @returns {*}
     */
    static cryptPassword = (password: any) => {
        return bcrypt.hashSync(password, 10)
    };

    /**
     * VALIDATE PASSWORD
     * @param password
     * @param hash
     * @returns {*}
     */
    static checkPassword = (password: any, hash: any) => {
        return bcrypt.compare(password, hash);
    };

    /**
     * ORDER BY OBJECT BY PARAMETER
     * @param property
     * @param sortOrder
     * @returns
     */
    static OrderBy = (property: any, sortOrder: any = 1) => {
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return (a: any, b: any) => {
            try {
                if (sortOrder === -1) {
                    return b[property].localeCompare(a[property]);
                } else {
                    return a[property].localeCompare(b[property]);
                }
            } catch (e) {
            }
        }
    }

    /**
     * FORMAT AND REMOVE ALL ACCENTS
     * @param s
     * @returns
     */
    static noAccents = (s: string, space: any = "") => {
        let r: any = s.toLowerCase();
        r = r.replace(new RegExp(/\s/g), space);
        r = r.replace(new RegExp(/[àáâãäå]/g), "a");
        r = r.replace(new RegExp(/æ/g), "ae");
        r = r.replace(new RegExp(/ç/g), "c");
        r = r.replace(new RegExp(/[èéêë]/g), "e");
        r = r.replace(new RegExp(/[ìíîï]/g), "i");
        r = r.replace(new RegExp(/ñ/g), "n");
        r = r.replace(new RegExp(/[òóôõö]/g), "o");
        r = r.replace(new RegExp(/œ/g), "oe");
        r = r.replace(new RegExp(/[ùúûü]/g), "u");
        r = r.replace(new RegExp(/[ýÿ]/g), "y");
        r = r.replace(new RegExp(/\W/g), space);
        return r;
    };

    /**
     * SHOW IMAGEM PATH SERVER
     * @param local
     * @param image
     * @param entity
     * @param thumb
     * @returns
     */
    static _showImage = (local: any, image: any, entity: any, thumb: any = false) => {
        let URL_API: any = Utils.getEnvironment('api');
        URL_API = URL_API.split('/');
        URL_API.pop('api');
        URL_API = URL_API.join('/');

        let getDomain: any;

        let [, host_name,]: any = document.location.hostname.split('.');
        getDomain = host_name;


        if (process.env.REACT_APP_ENV === 'developer') {
            // getDomain = 'localhost';
        }

        const getEntity: any = sessionStorage.getItem('entity');
        try {
            let src: any
            if (thumb) {
                src = `${URL_API}/uploads/${getDomain}/${local}/thumb_${image.filename}`;
            } else {
                src = `${URL_API}/uploads/${getDomain}/${local}/${image.filename}`;
            }

            if (!image) {
                const imageEntity: any = JSON.parse(getEntity) || null;
                let imageEmpty: any = `${URL_API}/images/NoImages.png`
                if (Object.keys(imageEntity).includes('logo_product') && imageEntity.logo_product) {
                    imageEmpty = `${URL_API}/uploads/${getDomain}/entities/${imageEntity?.logo_product?.filename}`;
                }
                src = imageEmpty;
            }
            return src
        } catch (e) {
            let logo: any;
            let imageEmpty: any = `${URL_API}/images/NoImages.png`;
            if (image) {
                if (document.location.pathname.indexOf('detail') !== -1) {
                    logo = `${URL_API}/uploads/${getDomain}/entities/${entity?.logo_white?.filename}`;
                } else {
                    logo = `${URL_API}/uploads/${getDomain}/entities/${entity?.logo?.filename}`;
                }
                return logo;
            } else {
                return imageEmpty;
            }
        }
    }

    static showImage = (local: any, data: any, field: any = 'cover', thumb: any = false) => {
        try {
            let URL_API: any = Utils.getEnvironment('api');
            let src: any = `${URL_API}/image/view/${local}/${field}/${data[field]?.filename.split('.')[0]}${thumb ? '?thumb=true' : ''}`;
            return src;
        } catch (error: any) {
            console.log(error);
        }
    }
    /**
     * FORMAT Calculation MONEY
     * @param value
     * @returns
     */
    static formatValue = (value: any) => {
        return (parseFloat(value)).toLocaleString('pt-BR',
            {
                style: 'currency',
                currency: 'BRL'
            }
        );
    }

    /**
     * GENERATE OBJECTID
     * @returns
     */
    static ObjectId = () => {
        var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
        return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
            return (Math.random() * 16 | 0).toString(16);
        }).toLowerCase();
    };

    /**
     * GET CURRENT DATE UTC
     */
    static getDateUTC() {
        const date: any = new Date();
        let new_date: any = new Date(date - 3600 * 1000 * 3);
        return new_date.toISOString();
    }

    /**
     * TOFXD NOT ARROUNDING
     * @param num
     * @param dec
     */
    static toFixed(num: any, dec: any) {
        dec = dec || 0;
        let s = String(num);
        if (num % 1) s = s.replace(/5$/, '6');
        return Number((+s).toFixed(dec));
    }

    /**
     * RADIUS
     * @param x
     */
    static rad = (x: any) => {
        return x * Math.PI / 180;
    };

    /**
     * CALCULATE DISTANCE BETWEEN POINTS
     * @param p1
     * @param p2
     */
    static getDistance = (p1: any, p2: any) => {
        let R: any = 6378137;
        let dLat: any = Utils.rad(p2.lat - p1.lat);
        let dLong: any = Utils.rad(p2.lng - p1.lng);
        let a: any = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(Utils.rad(p1.lat)) * Math.cos(Utils.rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
        let c: any = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d: any = R * c;
        return d;
    };

    /**
     * BASE64 BIONARY
     * @param base64String
     */
    static urlBase64ToUint8Array(base64String: any) {
        try {
            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                .replace(/-/g, '+')
                .replace(/_/g, '/');

            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        } catch (error: any) {
            return window.btoa(unescape(encodeURIComponent(base64String)));
        }
    }

    /**
     * GET CARD NUMBER
     * @param number
     */
    static getCardByNumber = (number: any) => {

        let re: any;

        // Diners - Carte Blanche
        re = new RegExp("^30[0-5]");
        if (number.match(re) != null)
            return {type: 'diners', name: "Diners - Carte Blanche"};

        // Diners
        re = new RegExp("^(30[6-9]|36|38)");
        if (number.match(re) != null)
            return {type: 'diners', name: "Diners"};

        // JCB
        re = new RegExp("^35(2[89]|[3-8][0-9])");
        if (number.match(re) != null)
            return {type: 'jcb', name: "JCB"};

        // AMEX
        re = new RegExp("^3[47]");
        if (number.match(re) != null)
            return {type: 'amex', name: "AMEX"};

        // Visa Electron
        re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
        if (number.match(re) != null)
            return {type: 'visa', name: "Visa Electron"};

        // Visa
        re = new RegExp("^4");
        if (number.match(re) != null)
            return {type: 'visa', name: "Visa"};

        // Mastercard
        re = new RegExp("^5[1-5]");
        if (number.match(re) != null)
            return {type: 'mastercard', name: "Mastercard"};

        // Discover
        re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
        if (number.match(re) != null)
            return {type: 'discover', name: "Discover"};

        return null;
    };

    /**
     * COPY CLIPBORAD TEXT
     * @param value
     * @param successfully
     * @param failure
     */
    static copyToClipboard = (value: any, successfully: any, failure: any) => {
        const clipboard = navigator.clipboard;
        // @ts-ignore
        if (clipboard !== undefined && clipboard !== "undefined") {
            navigator.clipboard.writeText(value).then(successfully, failure);
        } else {
            if (document.execCommand) {
                const el = document.createElement("input");
                el.value = value;
                document.body.append(el);
                el.select();
                el.setSelectionRange(0, value.length);
                if (document.execCommand("copy")) {
                    successfully();
                }
                el.remove();
            } else {
                failure();
            }
        }
    };

    /**
     * CHECK CURRENT DEVICE
     * @param device
     */
    static isCurrent = (device: any) => {
        const {appVersion, useAgent}: any = window.navigator;
        return device?.appVersion === appVersion && device?.useAgent === useAgent;
    }

    /**
     * GET ENVIRONMENT ACTUAL
     * @param name
     */
    static getEnvironment = (name: any) => {
        let {REACT_APP_ENV}: any = process.env;
        REACT_APP_ENV = REACT_APP_ENV.toUpperCase();
        return process.env[`REACT_APP_${REACT_APP_ENV}_${name.toUpperCase()}`];
    }

    /**
     * REGISTER DEVICE FOR NOTIFICATIONS
     * @param key
     * @param callback
     * @param callback_error
     */
    static registerNotification = (key: any, callback: any, callback_error: any) => {
        try {
            navigator.permissions.query({name: 'notifications'}).then((response: any) => {
                const {state}: any = response;
                if (!('serviceWorker' in navigator)) return;
                (async () => {
                    if (state === 'prompt') {
                        Notification.requestPermission().then(async (permission: any) => {
                            const registration: any = await navigator.serviceWorker.ready;
                            console.log('A', registration);
                        });
                    } else if (state === 'granted') {
                        const registration: any = await navigator.serviceWorker.ready;
                        registration.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: Utils.urlBase64ToUint8Array(key)
                        })
                            .then((result: any) => callback(result))
                            .catch((error: any) => callback_error(error));
                    }
                })();
            });
        } catch (error: any) {
            callback_error(error);
        }
    }

    /**
     * UNREGISTER NOTIFICATION
     * @param callback
     * @param callback_error
     */
    static unregisterNotification = (callback: any, callback_error: any) => {
        (async () => {
            if (!('serviceWorker' in navigator)) return;
            const registration = await navigator.serviceWorker.ready;
            registration.pushManager.getSubscription().then((subscription: any) => {
                subscription.unsubscribe().then((result: any) => callback(result)).catch((error: any) => callback_error(error));
            });
        })();
    }

    /**
     * GET DEVICE ENDPOINT
     * @param callback
     */

    static getRegisterNotification = () => {
        if (!('serviceWorker' in navigator)) return;
        return new Promise(async (resolve: any, reject: any) => {
            const registration = await navigator.serviceWorker.ready;
            return registration.pushManager.getSubscription()
                .then((success: any) => resolve(success))
                .then((error: any) => reject(error));
        });
    }

    static getRegister = (callback: any) => {
        Notification.requestPermission().then((permission: any) => {
            if (permission === 'granted') {
                navigator.serviceWorker.ready.then((registration: any) => {
                    if (registration.active.state === 'activated') {
                        registration.pushManager.getSubscription().then((device: any) => {
                            callback(device);
                        });
                    }
                });
            }
        });
    }

    /**
     * CREATE CODE RANDOMIC
     */
    static generateCode = () => {
        return String((Math.floor(10000 + Math.random() * 90000))).slice(0, 4);
    }

    static LimitText = (text: any, limit: any) => {
        let content: any = text.replace(/<[^>]*>?/gm, ' ');
        content = content.split(' ');
        if (limit < content.length) {
            content = content.slice(0, limit);
        }
        return `${content.join(' ')}...`;
    }

    static formateDateString(date: any) {
        return date?.split('T')[0].split('-').reverse().join('/')
    }

    static getYearForDate(date: any) {
        return date?.split('T')[0]?.split('-')[0];
    }

    static formateDate(date: any) {
        if (typeof date) {
            return date.split('T')[0].split('-').reverse().join('/')
        }
        return date;
    }

    static getDateTimeCurrent() {
        const date: any = new Date();
        let new_date: any = new Date(date - 3600 * 1000 * 3);
        return new_date.toISOString();
    }

    //@ts-ignore
    static addWrappedText({text, textWidth, doc, fontSize = 10, fontType = 'normal', lineSpacing = 7, xPosition = 10, initialYPosition = 10, pageWrapInitialYPosition = 10}): any {
        var textLines = doc.splitTextToSize(text, textWidth); // Split the text into lines
        var pageHeight = doc.internal.pageSize.height;        // Get page height, well use this for auto-paging
        doc.setFontType(fontType);
        doc.setFontSize(fontSize);
        var cursorY = initialYPosition;
        textLines.forEach((lineText: any) => {
            if (cursorY > pageHeight) { // Auto-paging
                doc.addPage();
                cursorY = pageWrapInitialYPosition;
            }
            doc.text(xPosition, cursorY, lineText);
            cursorY += lineSpacing;
        })
    }

}