import React, {createRef} from "react";
import style from "./style.module.scss";

import * as appActionCreators from "../../store/actions/appActionCreators";
import {connect} from "react-redux";

class ModalBox extends React.Component<any, any> {

    state = {
        opened: false
    }

    modal = createRef<any>();

    closeModal = () => {
        this.props.setModal(null);
    }

    callback = (btn: any) => {
        if (btn?.callback) {
            btn.callback(this);
        } else {
            this.closeModal();
        }
    }

    render() {
        const {modal}: any = this.props;
        const {children, title, message, buttons, label, component, icon, callback}: any = modal || {};
        if (!modal) return null;
        return (<>
            <div ref={this.modal} className={style.Drop}>
                <article>
                    {icon && <figure><i className={`fa-solid fa-${icon} fa-lg`}/></figure>}
                    {title && <label>{title}</label>}
                    {message && <span dangerouslySetInnerHTML={{__html: message}}/>}
                    {children && <section>{children}</section>}
                    {component && React.cloneElement(component, {...this.props, ...this.state})}
                    <div className={style.Footer}>
                        {!buttons && <button onClick={() => callback ? callback(this) : this.closeModal()}>{label || 'OK'}</button>}
                        {!!buttons && Object.values(buttons).map((btn: any, idx: number) =>
                            <button key={idx} onClick={() => btn.close ? this.closeModal() : this.callback(btn)}>{btn.label}</button>
                        )}
                    </div>
                </article>
            </div>
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.app});
export default connect(mapStateToProps, appActionCreators)(ModalBox);